import { EMPTY_STRING } from '../../constants';
import { isEmpty } from './empty';
import { GenericObject } from './generics';

const queryStringFromObject = (obj: GenericObject, opts: { excludeNullish?: boolean } = {}) => {
  const str = [];
  for (const property in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, property)) {
      continue;
    }
    if (opts.excludeNullish && obj[property] == null) {
      continue;
    }
    if (Array.isArray(obj[property])) {
      for (const subProperty of obj[property]) {
        str.push(`${encodeURIComponent(property)}=${encodeURIComponent(subProperty)}`);
      }
      continue;
    }
    const value = obj[property] == null ? '' : obj[property];
    str.push(`${encodeURIComponent(property)}=${encodeURIComponent(value)}`);
  }

  return str.join('&');
};

const isEmptyString = (str?: string) =>
  typeof str === 'undefined' || str === null || str.length === 0;

const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const firstNLetters = (str: string, n: number) => {
  if (str.length > 0) {
    return str.substring(0, n);
  }

  return null;
};

const capitalize = (str: string) => {
  if (!str) {
    return str;
  }

  if (str.length === 1) {
    return str.toUpperCase();
  }

  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const leftPad = (paddingChar: string, padCount: number, str: string) =>
  `${paddingChar.repeat(padCount)}${str}`.slice(-padCount);

const numberWithCommas = (x: number | string) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatPercent = (decimal: number) => `${decimal * 100}%`;

const formatPrice = (price: string) => {
  if (isEmptyString(price)) {
    return '$0';
  }

  const numericPrice = parseFloat(price);

  const priceWDecimals = numericPrice % 1 === 0 ? price : numericPrice.toFixed(2);

  return `$${numberWithCommas(priceWDecimals)}`;
};

const replaceWhiteSpaces = (str: string, char: string) => {
  if (!isEmptyString(str)) {
    return str.replace(/ /g, char);
  }

  return '';
};

/**
 * Pluralize a word. E.g.:
 * pluralize(1, 'lay') => 'lay'
 * pluralize(2, 'egg') => 'eggs'
 * pluralize(3, 'hatch', 'es') => 'hatches'
 * pluralize(4, 'octopus', 'i', '2') => 'octopi'
 * @param {number} number
 * @param {string} word
 * @param {string} postfix
 * @param {number} trim
 * @return {string}
 */
const pluralize = (number: number, word: string, postfix = 's', trim = 0) => {
  if (number === 1) {
    return word;
  }

  let pluralized = word;
  if (trim > 0) {
    pluralized = pluralized.substring(0, pluralized.length - trim);
  }

  pluralized += postfix;
  return pluralized;
};

const titleCase = (str: string) => {
  const string = str.toLowerCase().split(' ');

  for (let i = 0; i < string.length; i++) {
    // @ts-ignore
    string[i] = string[i].split('');
    // @ts-ignore
    string[i][0] = string[i][0].toUpperCase();
    // @ts-ignore
    string[i] = string[i].join('');
  }

  return string.join(' ');
};

const formatPhone = (phone: string) => {
  if (isEmpty(phone)) {
    return '';
  }
  return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 12)}`;
};

const formatStringPhone = (phone: string) =>
  !isEmptyString(phone) &&
  `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;

const stripNonnumeric = (string: string) => {
  if (!string) {
    return '';
  }

  return string.replace(/[^\d]/g, '');
};

const stripParentheses = (string: string) => string.replace(/[()]/g, '');

const rightPad = (string: string, character: string, number = 1) =>
  `${string}${new Array(number + 1).join(character)}`;

const makeAcronym = (string: string) =>
  string
    .split(' ')
    .map((word) => word.substring(0, 1).toUpperCase())
    .join('');

const comparator = (a: string, b: string) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }

  return 0;
};

const emptyStringToNull = (str: string) => (isEmptyString(str) ? null : str);

const leftStrip = (str: string, chars: string) => {
  const re = new RegExp('^' + chars); // eslint-disable-line prefer-template
  return str.replace(re, EMPTY_STRING);
};

const camelToSnake = (str: string) => {
  let newStr = str.replace(
    /([A-Z])/g,
    (originalString, firstCharacter) => `_${firstCharacter.toLowerCase()}`
  );

  if (newStr.slice(0, 1) === '_') {
    newStr = newStr.slice(1);
  }

  return newStr;
};

const areStringsDiffByNumChars = (a: string, b: string, num: number) => {
  const length = Math.min(a.length, b.length);
  let characterCount = 0;
  for (let i = 0; i < length; i++) {
    if (a.charAt(i) === b.charAt(i)) characterCount++;
  }

  return characterCount <= num;
};

const arrayToEnglish = (arr: string[]) => {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(' and ');
  }

  const last = arr.slice(-1);
  const first = arr.slice(0, -1).join(', ');
  return [first, last].join(', and ');
};

export {
  arrayToEnglish,
  capitalize,
  emptyStringToNull,
  queryStringFromObject,
  isEmptyString,
  isValidJson,
  leftPad,
  firstNLetters,
  formatPrice,
  formatPercent,
  replaceWhiteSpaces,
  pluralize,
  titleCase,
  formatPhone,
  stripNonnumeric,
  stripParentheses,
  rightPad,
  makeAcronym,
  comparator,
  numberWithCommas,
  leftStrip,
  camelToSnake,
  areStringsDiffByNumChars,
  formatStringPhone,
};
