import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { isDev } from '../config';

export function useSentry() {
  useEffect(() => {
    if (!isDev()) {
      Sentry.init({
        dsn: 'https://82b1c71a417441dfb78798e1782f324a@o570890.ingest.sentry.io/107173',
        tracesSampleRate: 0.0,
        environment: process.env.NEXT_PUBLIC_SOLV_ENV || 'production',
      });
    }
  }, []);
}
