import * as LDClient from 'launchdarkly-js-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { log } from '../logger/log';
import { getClientConfig } from '../config/nextConfig';
import { useDispatch, useSelector } from '../../types/Redux';
import { v4 as uuidV4 } from 'uuid';
import { Account } from '../../types/RootState';
import { setFlags } from '../../ducks/flags';
import { useDeepDiff } from '../../hooks/useDeepDiff';
import { useInterval } from 'beautiful-react-hooks';

let client: LDClient.LDClient | undefined;

export enum Step {
  ClientLoading,
  ClientLoaded,
  IdentifyUser,
  Ready,
}

const generateIdentifyPayload = (user?: Account) => {
  let id = localStorage.getItem('launch_darkly_anonymous_id');
  if (!id) {
    id = uuidV4();
    localStorage.setItem('launch_darkly_anonymous_id', id);
  }
  if (!user) {
    return {
      key: id,
      anonymous: true,
    };
  }

  return {
    key: `manage_clinic_account_${user.id}`,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email || user.username,
    custom: {
      roles: user.roles,
      groups: user.groups.map((g) => g.group_id),
    },
  };
};

export async function identifyUserForFlags(user: Account) {
  try {
    await client?.waitForInitialization();
    const payload = generateIdentifyPayload(user);
    console.debug('identifying user for ld', payload);
    await client?.identify(payload);
  } catch (ex) {
    // If it fails... not much we can do.
    log.error(ex);
  }
}

let nonReactiveFlags: Record<string, any> = {};

export function useInitializeFlags() {
  const user = useSelector((s) => s.login.account);
  const flags = useSelector((s) => s.flags.flags);
  const dispatch = useDispatch();

  const [step, setStep] = useState<Step>(Step.ClientLoading);

  const identify = useCallback(async () => {
    await identifyUserForFlags(user);
    dispatch(setFlags(client?.allFlags() || {}));
    setStep(Step.Ready);
  }, [dispatch, user]);

  useEffect(() => {
    // Make sure to identify after logging in
    if (user) {
      identify().catch();
    }
  }, [identify, user]);

  useEffect(() => {
    nonReactiveFlags = flags;
  }, [flags]);

  useEffect(() => {
    switch (step) {
      case Step.ClientLoaded:
        if (user) {
          setStep(Step.IdentifyUser);
        } else {
          setStep(Step.Ready);
        }
        break;
      case Step.IdentifyUser:
        identify().catch();
        break;
    }
  }, [identify, step, user]);

  useEffect(() => {
    console.debug('initializing launch darkly', Step[step]);
  }, [step]);

  useEffect(() => {
    if (client) {
      return;
    }
    try {
      client = LDClient.initialize(
        getClientConfig('NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_KEY') || '',
        generateIdentifyPayload(user)
      );
      client?.on('change', (value) => {
        console.debug('flag change', value);
        const copy = { ...flags };
        Object.keys(value).forEach((flag) => {
          copy[flag] = value[flag].current;
        });
        dispatch(setFlags(copy));
      });
      client?.on('initialized', () => {
        if (step !== Step.Ready) {
          setStep(Step.ClientLoaded);
        }
      });
      client?.on('failed', () => {
        // If it errors, not really able to do anything, just render the app without flags.
        setStep(Step.Ready);
      });
      client?.on('error', () => {
        // If it errors, not really able to do anything, just render the app without flags.
        setStep(Step.Ready);
      });
    } catch (ex) {
      setStep(Step.Ready);
    }
  }, []);

  return step === Step.Ready;
}

export function getFlag<T>(key: string, defaultValue?: T): T {
  return nonReactiveFlags[key] ?? defaultValue;
}

export function getBooleanFlag(key: string, defaultValue?: boolean): boolean {
  return !!getFlag(key, defaultValue);
}

export function useFlags(): Record<string, any> {
  return useSelector((s) => s.flags.flags);
}

export function useFlag<T>(key: string, defaultValue?: T): T {
  return useSelector((s) => s.flags.flags[key] ?? defaultValue);
}

export function useBooleanFlag(key: string) {
  return useFlag<boolean>(key, false);
}
