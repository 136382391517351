import { DAPI_HOST } from '../../config';

const getInsurersListUrl = searchStateCode =>
  `${DAPI_HOST}/v1/insurers?` +
  'fields=id,name,display_name,insurer_code&limit=0' +
  `&popular_only=True&state=${searchStateCode}`;

const getFullInsurersListUrl = () =>
  `${DAPI_HOST}/v1/insurers?` + 'fields=id,name,display_name,insurer_code&limit=0';

export { getInsurersListUrl, getFullInsurersListUrl };
