const isNumber = number => typeof number === 'number' && number !== null;

const isNumberBetween = (number, min, max) =>
  /^[0-9]+$/.test(number) && number >= min && number <= max;

const isWholeNumber = number => typeof number !== 'undefined' && number % 1 === 0;

const roundFloat = (number, decimalPlaces) => parseFloat(number).toFixed(decimalPlaces);

/** *
 * Takes an integer and rounds it to the nearest `roundTo`.
 * @param integer Number to round
 * @param roundTo What to round to, default 10
 * @returns {number}
 */
const roundInt = (integer, roundTo = 10) => Math.ceil(integer / roundTo) * roundTo;

const formatNumberWithCommas = price => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export { isNumber, isNumberBetween, isWholeNumber, roundFloat, roundInt, formatNumberWithCommas };
