import { LANG_MAP } from '../../constants/lang';
import { LabelValue } from '../../core/form/common';
import { Brand, ImageLinks, Location, LocationUnformatted, SpecialtyV2 } from '../RootState';

/**
 * Returns true if the location is LocationV2 (which means all the fields are camelcase)
 * We can tell if its the old Location type if some of the non top level fields are snake case.
 * Where-as locationV2, none of the fields are snake case.
 * @param location
 */
export function isLocationV2<
  LocV2 extends Pick<LocationV2, 'imageLinks'> | Pick<LocationV2, 'trackingProperties'>
>(
  location: (Location & LocationUnformatted) | Location | LocationUnformatted | LocV2
): location is LocV2 {
  if (!location) {
    return false;
  }
  if ('location_id' in location) {
    return false;
  }
  if ('trackingProperties' in location) {
    if ('googleAnalytics' in (location.trackingProperties ?? {})) {
      return true;
    }
    if ('google_analytics' in (location.trackingProperties ?? {})) {
      return false;
    }
  }
  if ('tracking_properties' in location) {
    return false;
  }
  if ('imageLinks' in location) {
    if ('discharge_survey_image' in (location.imageLinks ?? {})) {
      return false;
    }
    if ('dischargeSurveyImage' in (location.imageLinks ?? {})) {
      return true;
    }
  }
  if ('image_links' in location) {
    return false;
  }

  return false;
}

export interface LocationV2 {
  acceptedInsuranceTypes: AcceptedInsuranceTypes;
  acceptedInsurances: any[];
  acceptedInsurers: any[];
  acceptedPaymentMethods: any[];
  accreditations: Record<string, string>;
  active: boolean;
  address: string;
  adjectives: any[];
  appointmentInterval: number;
  appointmentIntervalReservations: any;
  appointmentSlots: number;
  areBookingPrescreenQuestionsEnabled: boolean;
  associatedTelemedLocationId: string;
  attributes: Record<string, string>;
  availability: Availability;
  availabilityRecommendations: string;
  beyondNextDayLimit: number;
  bookingPrescreenQuestionsText: string;
  bookingUrl: string;
  bookingWidgetLanguages: Array<keyof typeof LANG_MAP> | undefined;
  brandName: string;
  brandUrl: string;
  brands: Brand[];
  category: string[];
  city: string;
  contractSignedDate: string;
  contractedBookingPageEngagement: number;
  createdDate: string;
  crossStreets: string;
  customBookingMessage: string;
  customCoronavirusSmsCopy: string;
  customDescription: string;
  customPhotoIdUploadCopy: string;
  customWalkInSmsCopy: string;
  daysToExpiration: number;
  descriptionVariationTest: string;
  disableReservationsUntil: string;
  displayAddress: string;
  displayNameAlternate: string;
  displayNamePrimary: string;
  displayNameSecondary: string;
  displayNameTertiary: string;
  distanceFromCurrentLocation: number;
  email: string;
  externalTelemedCustomNextSteps: string;
  externalTelemedCustomTips: string;
  externalBookingAutomated: boolean;
  externalBookingSystem: string;
  externalBookingUrl: string;
  fax: string;
  geo: {
    latitude: string;
    longitude: string;
  };
  googleFeatureId: string;
  googlePlaceId: string;
  googleReviewSource: string;
  googleHealthAppointmentsEnabled: boolean;
  googleReserveEnabled: boolean;
  groups: Group[];
  hasBoost: boolean;
  hasNextInLine: boolean;
  hasProviderSchedule: boolean;
  hospitalAffiliations: any[];
  hours: Hours;
  hoursDefault: Hours;
  id: string;
  imageLinks: ImageLinks;
  insideOf: string;
  isAccountActivationEmailDisabled: boolean;
  isAddressHidden: boolean;
  isAdultPatientsAccepted: boolean;
  isAppointmentReminderLateSmsDisabled: boolean;
  isAsapTelemedEnabled: boolean;
  isBeyondNextDayAppointmentsEnabled: boolean;
  isBirthSexRequired: boolean;
  isBookAheadOnWalkInSlots: boolean;
  isBookable: boolean;
  isBookingCodesEnabled: boolean;
  isBookingWidgetAddressRequired: boolean;
  isCdpSrpAddressRequired: boolean;
  isBookingWidgetEnabled: boolean;
  isByAppointmentOnly: boolean;
  isCancellationSmsWithoutRebookingPrompt: boolean;
  isCannedTextChatOnlyEnabled: boolean;
  isClearPricePartner: boolean;
  isClockwiseCustomer: boolean;
  isConsentEnabled: boolean;
  isCoronavirusSmsEnabled: boolean;
  isCovidTestFree: boolean;
  isCovidTestingOnly: boolean;
  isCustomWebRtcConfigEnabled: boolean;
  isCustomWalkInSmsEnabled: boolean;
  isCustomQuickReplyEnabled: boolean;
  isDefaultDescriptionHidden: boolean;
  isDosageDropdownEnabled: boolean;
  isEmailRequiredForBookingWidget: boolean;
  isExternalTelemed: boolean;
  isExternalTelemedModifiedSms: boolean;
  isExternalTelemedModifiedEmail: boolean;
  isFamilyBookingsEnabled: boolean;
  isInMarketplace: boolean;
  isInServiceSearchesOnly: boolean;
  isInsuranceDisabledFromBooking: boolean;
  isInsuranceOcrInPaperworkFlowEnabled: boolean;
  isInsurancePhotoUploadAndOcrDisabled: boolean;
  isInsurancePlanNameRequired: boolean;
  isInsuranceRequiredForBooking: boolean;
  isKioskCustomQuestionsEnabled: boolean;
  isKioskEnabled: boolean;
  isLegalNameRequired: boolean;
  isMarketplacePriority: boolean;
  isMobileCheckInEnabled: boolean;
  isPaperlessHandoffEnabled: boolean;
  isPaperworkCustomQuestionsEnabled: boolean;
  isPaperworkEnabled: boolean;
  isPaperworkRequired: boolean;
  isPaymentToggleHidden: boolean;
  isPaymentsEnabled: boolean;
  isPaymentsRequired: boolean;
  isPediatricPatientsAccepted: boolean;
  isPediatricsOnly: boolean;
  isPerformancePricingEnabled: boolean;
  isPhotoIdBackRequired: boolean;
  isPhotoIdFrontRequired: boolean;
  isPhotoIdUploadEnabled: boolean;
  isPostVisitReviewSmsEnabled: boolean;
  isPostVisitSummaryEmailEnabled: boolean;
  isPostVisitReviewEmailEnabled: boolean;
  isProvider: boolean;
  isReasonForVisitDropdownEnabled: boolean;
  isReserveWithGoogleGmbLinkDisabled: boolean;
  isSmoochEnabled: boolean;
  isSolvPartner: boolean;
  isStandaloneTelemed: boolean;
  isTelemed: boolean;
  isTelemedConnect: boolean;
  isTestLocation: boolean;
  isTimezoneInBookingSmsEnabled: boolean;
  isTwilioTendlcEnabled: boolean;
  isUberEnabled: boolean;
  isVaccinationOnly: boolean;
  isVideoVisitHandoffEnabled: boolean;
  isViewable: boolean;
  isViewableOnDirectory: boolean;
  isViewableOnGroupMultiLocationWidget: boolean;
  isViewableOnNearbyModules: boolean;
  isVirtualVisitTransferToPhysicalEnabled: boolean;
  isWaitlistSmsDisabled: boolean;
  isWidgetTelemedEnabled: boolean;
  lastBooked: string;
  lastVerifiedOn: string;
  lastWalkinAppointmentDate: string;
  latLong: {
    latitude: string;
    longitude: string;
  };
  laurenScore: number;
  links: Link[];
  liveDate: string;
  locationIdNumeric: number;
  market: string;
  mobileCheckIn?: boolean;
  name: string;
  neighborhoods: any[];
  npi: string;
  offboardedDate: string;
  officeId: string;
  officeLocationDescription: string;
  officeLocationDisplayName: string;
  ownership: string;
  package: null | undefined | LocationPackage;
  packageId: string;
  packageName: string;
  pactSafeContractId: string;
  pactSafeSiteId: string;
  parkingDetails: string;
  paymentAccountId: string;
  performancePricingMonthlyBudget: string;
  phone: string;
  photoIdUploadEntrypoints: any[];
  platformType: string;
  premiumServiceOffering: string;
  providerNames: string[];
  ratingBedsideManner: number;
  ratingBedsideMannerCount: number;
  ratingFacilityCleanliness: number;
  ratingFacilityCleanlinessCount: number;
  ratingNonSolv: number;
  ratingNonSolvCount: number;
  ratingQualityOfCare: number;
  ratingQualityOfCareCount: number;
  ratingSolv: number;
  ratingSolvCount: number;
  ratingStaffFriendliness: number;
  ratingStaffFriendlinessCount: number;
  ratingWaitTime: number;
  ratingWaitTimeCount: number;
  reasonForVisitDropdownOptions: LabelValue[];
  recentBookings: number;
  reservationBuffer: number;
  revenuePerVisit: number;
  reviewFlowType: string;
  scores: Scores;
  serviceLocations: any[];
  servicePreferences: string;
  servicePrices: any[];
  services: Record<string, string[]>;
  servicesObj: Record<string, string[]>;
  slots: Slot[];
  solvQuote: string;
  source: string;
  specialOperatingHours: any[];
  specialties: SpecialtyV2[];
  state: string;
  status: string;
  subpremise: string;
  telemedSla: string;
  timeZone: string;
  topReviewJson: {
    date: string;
    rating: number;
    text: string;
    verifiedPatient: boolean;
    hasConsentedToPublishPatientName: boolean;
    hasConsentedToPublishVisitType: boolean;
    isExternalTelemed: boolean;
    patientName: string;
  };
  trackingProperties: {
    googleAnalytics: {
      id: string;
      referringDomain: string;
      trackerName: string;
      forceSsl?: boolean;
    };
    googleAdwords: {
      trackingId: string;
      conversionLabel: string;
    };
    googleAnalytics4: {
      id: string;
    };
  };
  uclId: string;
  updatedDate: string;
  urlSlug: string;
  waitListCount: number;
  waitTime: number;
  website: string;
  yearOpened: number;
  zipCode: string;
  twilioPhoneLongcode: string;
}

export interface LocationPackage {
  displayName: string;
  name: string;
  id: string;
  locationFeaturesFields: {
    [locationFeaturesColumnName: string]: any;
  };
  locationsFields: {
    [locationsColumnName: string]: any;
  };
  locationAttributesFlatFields: {
    [locationAttributesFlatColumnName: string]: any;
  };
}

export interface AcceptedInsuranceTypes {
  medicaid: boolean;
  medicare: boolean;
  ppo: boolean;
  selfPay: boolean;
  selfPayOnly: boolean;
  tricare: boolean;
  triwest: boolean;
}

export interface Availability {
  reservation: Reservation;
  total: Reservation;
}

export interface Reservation {
  Friday: { [key: string]: number };
  Monday: { [key: string]: number };
  Saturday: { [key: string]: number };
  Sunday: { [key: string]: number };
  Thursday: { [key: string]: number };
  Tuesday: { [key: string]: number };
  Wednesday: { [key: string]: number };
}

export interface Group {
  configuration: Configuration;
  domains: null;
  groupId: string;
  name: string;
}

export interface Configuration {
  foobarFeature: boolean;
  multilocationWidget: MultilocationWidget;
}

export interface MultilocationWidget {
  bookCtaColor: string;
  enabled: boolean;
  mapMarkerColor: string;
}

export interface Hours {
  Friday: Day[];
  Monday: Day[];
  Saturday: Day[];
  Sunday: Day[];
  Thursday: Day[];
  Tuesday: Day[];
  Wednesday: Day[];
}

export interface Day {
  fromTime: string;
  toTime: string;
}

export interface Link {
  href: string;
  rel: string;
}

export interface Scores {
  aggregated: any[];
  web: any[];
}

export interface GeneralDentistry {
  dataSources: string[];
  details: Details | null;
  displayName: string;
  isLabResultsNotificationConfigured: boolean;
  isPopular: boolean;
  locationServiceId: string;
  maxPrice: null;
  minPrice: null;
  name: string;
  notes: null;
  price: null;
  serviceId: string;
  slug: string;
}

export interface Details {
  acceptCash: boolean;
  acceptInsurance: boolean;
  hasPriceRange: boolean;
  walkinsAccepted: boolean;
}

export interface Slot {
  appointmentDate: number;
  availability: number;
  busy: boolean;
  isReservationsDisabled: boolean;
}
