import {
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_CHECKED_IN,
  BOOKING_STATUS_CONFIRMED,
  BOOKING_STATUS_DISCHARGED,
  BOOKING_STATUS_IN_EXAM_ROOM,
  BOOKING_STATUS_NO_SHOW,
  BOOKING_STATUS_PENDING,
  BOOKING_STATUS_REJECTED,
  BOOKING_STATUS_RESERVED,
  BOOKINGS_ATTRIBUTION_TYPE_PROFILE_OPTIMIZATION,
  BOOKINGS_ATTRIBUTION_TYPE_SOLV,
  CANCELLATION_REASON_TRANSFERRED,
  ORIGIN_BOOKING_FORM,
  ORIGIN_BOOKING_WIDGET,
  ORIGIN_KIOSK_SIGN_IN,
  ORIGIN_MOBILE_APP,
  ORIGIN_PARTNER_API,
  ORIGIN_QUEUE,
  PAPERWORK_COMPLETED,
  PAPERWORK_IN_PROGRESS,
  PAPERWORK_INCOMPLETE,
  PREFERRED_TIME_ASAP,
  TYPE_HMO,
  TYPE_MEDICAID,
  TYPE_MEDICARE,
  TYPE_OTHER,
  TYPE_PPO,
  TYPE_SELF,
  TYPE_SKIP,
  TYPE_UNKNOWN,
  UBER_RIDE_STATUS_REQUESTED,
  UBER_RIDE_STATUS_SCHEDULED,
  WAIT_LIST_ORIGIN_ASAP,
  WAIT_LIST_ORIGIN_BOOK_AHEAD,
  WAIT_LIST_ORIGIN_ONLINE,
  WAIT_LIST_ORIGIN_PARTNER_API,
  WAIT_LIST_ORIGIN_SOLV,
  WAIT_LIST_ORIGIN_WALKIN,
  WAIT_LIST_ORIGIN_WEBSITE,
} from '../../constants';
import moment from 'moment-timezone';
import { isEmptyString } from './string';
import { isInNetwork, sufficientInsuranceDataForNPICheck } from './insurance';
import { isEmptyObject } from './object';
import { isTelemedLocation } from './location';
import { MONTH_NAME_DAY_ABBR_YEAR } from '../../components/DateTime';
import { log } from '../logger/log';
import { Booking, Location } from '../../types/RootState';
import { BookingV2 } from '../../types/dapi/Booking';

const isProviderInCall = (booking: Booking) =>
  !isEmptyString(booking.last_name_telemed_provider_in_call);

const isPaperworkCompleted = (booking: Booking) => booking.paperwork_status === PAPERWORK_COMPLETED;

const isPaperworkInProgress = (booking: Booking) =>
  booking.paperwork_status === PAPERWORK_IN_PROGRESS;

const isPaperworkIncomplete = (booking: Booking) =>
  booking.paperwork_status === PAPERWORK_INCOMPLETE;

const isPaperworkBypassed = (booking: Booking) => booking && booking.is_paperwork_bypassed;

const isPaymentBypassed = (booking: Booking) => booking && booking.is_payment_bypassed;

const areConsentFormsCompleted = (booking: Booking) =>
  !isEmptyString(booking.consent_forms_signed_date);

const isOpaqueBooking = (newBooking: Booking, location: Location, npi: boolean) => {
  if (location.isOpaque) {
    return true;
  }

  switch (newBooking.insurance.insurerType) {
    case TYPE_HMO:
    case TYPE_PPO:
    case TYPE_MEDICAID:
    case TYPE_MEDICARE:
    case TYPE_UNKNOWN:
    case TYPE_OTHER:
      return (
        !sufficientInsuranceDataForNPICheck(newBooking, location) ||
        !isInNetwork(newBooking, location, npi)
      );
    case TYPE_SELF:
    case TYPE_SKIP:
      return false;
    default:
      log.error('No insurer type provided');
      return true;
  }
};

const isLate = (booking: Booking) =>
  moment() > moment(booking.appointment_date) && booking.status === BOOKING_STATUS_RESERVED;

const isBookedAhead = (booking: Booking) =>
  [ORIGIN_MOBILE_APP, ORIGIN_BOOKING_WIDGET, ORIGIN_PARTNER_API].indexOf(booking.origin) !== -1 ||
  booking.is_call_ahead === true;

const getReservationType = (booking: Booking) => {
  if (booking.is_call_ahead) {
    return 'Call Ahead';
  }

  switch (booking.origin) {
    case ORIGIN_BOOKING_WIDGET:
    case ORIGIN_PARTNER_API:
    case ORIGIN_BOOKING_FORM:
    case ORIGIN_MOBILE_APP:
      return 'Online';
    case ORIGIN_QUEUE:
    case ORIGIN_KIOSK_SIGN_IN:
      return 'Walk In';
    default:
      return booking.origin;
  }
};

const getFacesheetBookingStatusOptions = (location: Location, isSolvExpressLocation: boolean) => {
  if (isSolvExpressLocation) {
    return [
      { value: BOOKING_STATUS_RESERVED, name: 'Reserved' },
      { value: BOOKING_STATUS_CONFIRMED, name: 'Here' },
      { value: BOOKING_STATUS_DISCHARGED, name: 'Done' },
      { value: BOOKING_STATUS_CANCELLED, name: 'Canceled' },
      { value: BOOKING_STATUS_NO_SHOW, name: 'No Show' },
    ];
  }
  if (isTelemedLocation(location)) {
    return [
      { value: BOOKING_STATUS_RESERVED, name: 'Requested' },
      { value: BOOKING_STATUS_CHECKED_IN, name: 'Claimed' },
      { value: BOOKING_STATUS_IN_EXAM_ROOM, name: 'In Call' },
      { value: BOOKING_STATUS_DISCHARGED, name: 'Done' },
      { value: BOOKING_STATUS_CANCELLED, name: 'Canceled' },
      { value: BOOKING_STATUS_NO_SHOW, name: 'No Show' },
    ];
  }

  return [
    { value: BOOKING_STATUS_RESERVED, name: 'Reserved' },
    { value: BOOKING_STATUS_CONFIRMED, name: 'Here' },
    { value: BOOKING_STATUS_CHECKED_IN, name: 'Ready' },
    { value: BOOKING_STATUS_IN_EXAM_ROOM, name: 'In Exam' },
    { value: BOOKING_STATUS_DISCHARGED, name: 'Done' },
    { value: BOOKING_STATUS_CANCELLED, name: 'Canceled' },
    { value: BOOKING_STATUS_NO_SHOW, name: 'No Show' },
  ];
};

const getStatusLabel = (status: string, isTelemed: boolean) => {
  if (isTelemed) {
    switch (status) {
      case BOOKING_STATUS_RESERVED:
        return 'Requested';

      case BOOKING_STATUS_CHECKED_IN:
        return 'Claimed';

      case BOOKING_STATUS_IN_EXAM_ROOM:
        return 'In Call';

      case BOOKING_STATUS_DISCHARGED:
        return 'Done';

      case BOOKING_STATUS_CANCELLED:
        return 'Canceled';

      case BOOKING_STATUS_NO_SHOW:
        return 'No Show';

      default:
        return null;
    }
  }

  switch (status) {
    case BOOKING_STATUS_PENDING:
      return 'Pending';

    case BOOKING_STATUS_RESERVED:
      return 'Reserved';

    case BOOKING_STATUS_CONFIRMED:
      return 'Here';

    case BOOKING_STATUS_CHECKED_IN:
      return 'Ready';

    case BOOKING_STATUS_IN_EXAM_ROOM:
      return 'In Exam';

    case BOOKING_STATUS_DISCHARGED:
      return 'Done';

    case BOOKING_STATUS_CANCELLED:
      return 'Canceled';

    case BOOKING_STATUS_NO_SHOW:
      return 'No Show';

    default:
      return null;
  }
};

/**
 * Prefers returning arrived timestamp if possible
 * if null then return appointment date
 */
const getArrivedOrAppointmentTime = (booking: Booking) =>
  !isEmptyString(booking.arrived_timestamp) ? booking.arrived_timestamp : booking.appointment_date;

const getDischargedOrAppointmentTime = (booking: Booking) =>
  !isEmptyString(booking.discharged_timestamp)
    ? booking.discharged_timestamp
    : booking.appointment_date;

const getOriginalAppointmentDate = (booking: Booking) => booking.original_appointment_date;

export const getPatientFirstName = (
  booking?: Pick<Booking, 'first_name'> | Pick<BookingV2, 'firstName'> | null | undefined
) => {
  return booking && 'first_name' in booking ? booking.first_name : booking?.firstName ?? '';
};

export const getPatientLastName = (
  booking?: Pick<Booking, 'last_name'> | Pick<BookingV2, 'lastName'> | null | undefined
) => {
  return booking && 'last_name' in booking ? booking.last_name : booking?.lastName ?? '';
};

const getPatientName = (
  booking?:
    | Pick<Booking, 'first_name' | 'last_name'>
    | Pick<BookingV2, 'firstName' | 'lastName'>
    | null
    | undefined
) => {
  return `${getPatientFirstName(booking)} ${getPatientLastName(booking)}`.trim();
};

const getPatientInitials = (
  booking?:
    | Pick<Booking, 'first_name' | 'last_name'>
    | Pick<BookingV2, 'firstName' | 'lastName'>
    | null
    | undefined
) => {
  return `${getPatientFirstName(booking)[0] ?? ''}${getPatientLastName(booking)[0] ?? ''}`.trim();
};

const isCallAhead = (booking: Booking) => booking.is_call_ahead;

const isClosedBooking = (booking: Booking) =>
  [
    BOOKING_STATUS_DISCHARGED,
    BOOKING_STATUS_CANCELLED,
    BOOKING_STATUS_NO_SHOW,
    BOOKING_STATUS_REJECTED,
  ].includes(booking.status);

const isPriority = (booking: Booking) => booking.is_priority;

const hasBeenTriaged = (booking: Booking) => booking.triaged_timestamp !== null;

const hasBeenDischarged = (booking: Booking) => booking?.discharged_timestamp !== null;

const hasSentFollowUpAppointmentText = (booking: Booking) =>
  !!booking.sent_follow_up_text_timestamp;

const patientHasRequestedUberRide = (booking: Booking) =>
  booking.uber_ride_status === UBER_RIDE_STATUS_REQUESTED;

const clinicHasScheduledUberRide = (booking: Booking) =>
  booking.uber_ride_status === UBER_RIDE_STATUS_SCHEDULED;

const getTvWaitlistOriginDisplayValue = (booking: Booking) => {
  if (isCallAhead(booking)) {
    return WAIT_LIST_ORIGIN_BOOK_AHEAD;
  }

  switch (booking.origin) {
    case ORIGIN_BOOKING_FORM:
    case ORIGIN_MOBILE_APP:
    case ORIGIN_BOOKING_WIDGET:
    case ORIGIN_PARTNER_API:
      return WAIT_LIST_ORIGIN_ONLINE;
    case ORIGIN_QUEUE:
    case ORIGIN_KIOSK_SIGN_IN:
      return WAIT_LIST_ORIGIN_WALKIN;
    default:
      return booking.origin;
  }
};

const isAsapBooking = (booking: Booking) => booking.preferred_time === PREFERRED_TIME_ASAP;

const isOnlineWaitlistBooking = (booking: Booking) => {
  // We confirmed that online waitlist bookings are the only non-telemed solv attributed bookings with ASAP time.
  // In the future we should consider refactoring our architecture to have a simpler way to distinguish online waitlist.
  return (
    booking.origin === ORIGIN_MOBILE_APP &&
    booking.preferred_time === PREFERRED_TIME_ASAP &&
    isEmptyString(booking.tokbox_session_id)
  );
};

const getQueueWaitlistOriginDisplayValue = (booking: Booking) => {
  if (!booking) {
    return null;
  }

  if (isAsapBooking(booking)) {
    return WAIT_LIST_ORIGIN_ASAP;
  }

  if (isCallAhead(booking)) {
    return WAIT_LIST_ORIGIN_BOOK_AHEAD;
  }

  switch (booking.origin) {
    case ORIGIN_BOOKING_FORM:
    case ORIGIN_MOBILE_APP:
      return WAIT_LIST_ORIGIN_SOLV;
    case ORIGIN_BOOKING_WIDGET:
      return WAIT_LIST_ORIGIN_WEBSITE;
    case ORIGIN_PARTNER_API:
      return WAIT_LIST_ORIGIN_PARTNER_API;
    case ORIGIN_QUEUE:
    case ORIGIN_KIOSK_SIGN_IN:
      return WAIT_LIST_ORIGIN_WALKIN;
    default:
      return booking.origin;
  }
};

const isTokboxTelemedBooking = (
  booking: Pick<Booking, 'tokbox_session_id'> | Pick<BookingV2, 'tokboxSessionId'>
) => {
  if (!booking) return false;
  if ('tokbox_session_id' in booking && booking.tokbox_session_id) return true;
  if ('tokboxSessionId' in booking && booking.tokboxSessionId) return true;
  return false;
};

const isPremiumVisit = (booking: Booking) => booking && booking.is_premium_visit;

const formatBookingDateOfBirth = (dateOfBirth: string): string => {
  if (!dateOfBirth) {
    return '';
  }

  const pattern = /(\d{4})-(\d{2})-(\d{2})/;

  if (!dateOfBirth.match(pattern)) {
    return '';
  }
  return dateOfBirth.replace(pattern, '$2/$3/$1');
};

const isWalkInBooking = (booking: Booking) => {
  if (!booking) {
    return null;
  }

  return getQueueWaitlistOriginDisplayValue(booking) === WAIT_LIST_ORIGIN_WALKIN;
};

const isReturningPatientBooking = (booking: Booking) => booking.is_existing_patient;

const isSolvAppPatient = (booking: Booking) => booking.origin === ORIGIN_MOBILE_APP;

const isBoostPatient = (booking: Booking) =>
  booking.attribution === BOOKINGS_ATTRIBUTION_TYPE_PROFILE_OPTIMIZATION ||
  booking.attribution === BOOKINGS_ATTRIBUTION_TYPE_SOLV;

const getAttributionSource = (attribution: string) => {
  if (attribution === 'profile') {
    return 'Web Optimization';
  }

  return 'Solv App';
};

const isTransferredBooking = (booking: Booking) =>
  Boolean(
    booking.transferred_from_display_name_primary ||
      booking.transferred_from_display_name_secondary ||
      booking.transferred_to_display_name_primary ||
      booking.transferred_to_display_name_secondary
  );

const isBookingCancelled = (booking: Booking) => {
  if (isEmptyObject(booking)) return false;
  return booking.status === BOOKING_STATUS_CANCELLED;
};

const isCancelledTransferredBooking = (booking: Booking) =>
  booking.status === BOOKING_STATUS_CANCELLED &&
  booking.cancellation_reason === CANCELLATION_REASON_TRANSFERRED;

const isActiveTransferredBooking = (booking: Booking) =>
  Boolean(
    booking.transferred_from_display_name_primary || booking.transferred_from_display_name_secondary
  );

const getBirthDate = (booking: Booking, dateFormat = MONTH_NAME_DAY_ABBR_YEAR) => {
  if (!booking.birth_date) {
    return null;
  }

  return `${moment(booking.birth_date).format(dateFormat)}`;
};

const getReason = (booking: Booking) => {
  if (booking.reason) {
    return `${booking.reason[0].toUpperCase()}${booking.reason.substring(1)}`;
  }

  return null;
};

const isSolvAppTelemedTokbox = (booking: Booking) =>
  isTokboxTelemedBooking(booking) && isSolvAppPatient(booking);

const isNoShow = (booking: Booking) => booking && booking.status === BOOKING_STATUS_NO_SHOW;

const isRejected = (booking: Booking) => booking.status === BOOKING_STATUS_REJECTED;

const isNewTransferBooking = (booking: Booking) =>
  booking.status === BOOKING_STATUS_RESERVED && booking.transferred_from_display_name_secondary;

const hasUploadedBothSidesPhotoId = (booking: Booking) =>
  booking && booking.has_uploaded_photo_id_front && booking.has_uploaded_photo_id_back;

const hasUploadedPhotoIdFront = (booking: Booking) =>
  booking && booking.has_uploaded_photo_id_front;

const getAreRequiredPaperworkFieldsAnswered = (booking: Booking) =>
  booking && booking.are_required_paperwork_fields_answered;

export {
  isProviderInCall,
  isCallAhead,
  isOpaqueBooking,
  isLate,
  isBookedAhead,
  isPaperworkInProgress,
  getReservationType,
  getFacesheetBookingStatusOptions,
  getStatusLabel,
  getArrivedOrAppointmentTime,
  getDischargedOrAppointmentTime,
  getPatientName,
  getPatientInitials,
  isPaperworkCompleted,
  isClosedBooking,
  isPriority,
  hasBeenTriaged,
  hasBeenDischarged,
  areConsentFormsCompleted,
  hasSentFollowUpAppointmentText,
  getTvWaitlistOriginDisplayValue,
  getQueueWaitlistOriginDisplayValue,
  patientHasRequestedUberRide,
  clinicHasScheduledUberRide,
  isAsapBooking,
  isOnlineWaitlistBooking,
  isTokboxTelemedBooking,
  formatBookingDateOfBirth,
  isWalkInBooking,
  isReturningPatientBooking,
  isSolvAppPatient,
  getOriginalAppointmentDate,
  isBoostPatient,
  getAttributionSource,
  isTransferredBooking,
  isActiveTransferredBooking,
  isCancelledTransferredBooking,
  isBookingCancelled,
  getBirthDate,
  getReason,
  isSolvAppTelemedTokbox,
  isNoShow,
  isRejected,
  isNewTransferBooking,
  isPaperworkBypassed,
  isPaymentBypassed,
  hasUploadedBothSidesPhotoId,
  hasUploadedPhotoIdFront,
  isPaperworkIncomplete,
  getAreRequiredPaperworkFieldsAnswered,
  isPremiumVisit,
};
