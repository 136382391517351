import { SetupIntentResponse } from '../../components/FacesheetModal/Payments/POS/V2PaymentForm/loader';
import { DAPI_CACTUS_HOST, DAPI_HOST } from '../../config';
import { httpGetJson, httpPostJson } from '../http/http';
import { SORT_DESC, sortByTimestamps } from '../util/array';
import { getAllChargesByBookingUrl, getV2ChargesPOSUrl } from './charges';
import { getPaymentCardUrl } from './invoices';
import { DapiDataResults, DapiResults, DapiSingleResult } from './response';

function getV2PaymentMethodsUrl(accountId: string, locationId: string) {
  return `${DAPI_CACTUS_HOST}/v2/payment-customers/cards/${accountId}?location_id=${locationId}`;
}

export type V1Charge = {
  id: string;
  permakey: string;
  invoice_id: string;
  payment_reference_id: string;
  customer_reference_id: string;
  amount: number;
  fee: number;
  amount_refunded: number;
  status: string;
  source: 'sms' | 'pos';
  error_type: string;
  error_code: string;
  error_decline_code: string;
  error_message: string;
  created_date: string;
  updated_date: string;
};

export type V1PaymentMethod = {
  brand: string;
  created_date: string;
  updated_date: string;
  expiration_month: number;
  expiration_year: number;
  is_default: boolean;
  last4: string;
  stripe_card_id: string;
  stripe_customer_id: string;
  zip_code: string;
};

export type V2PaymentMethod = {
  payment_method_id: string;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  type: 'card' | 'hsa' | 'fsa' | 'hsa-fsa';
};

const getExpressConnectUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/payment-accounts/stripe-account/${locationId}`;

const getPaymentAccountsUrl = (paymentAccountId: string) =>
  `${DAPI_HOST}/v1/payment-accounts/${paymentAccountId}`;

const getCreatePaymentAccountUrl = () => `${DAPI_HOST}/v1/payment-accounts`;

export { getCreatePaymentAccountUrl, getExpressConnectUrl, getPaymentAccountsUrl };

export class DapiPayments {
  /**
   * For a given account ID and location ID, return all payment methods this user has access to,
   * that are keyed to this location.
   * @param accountId The user account ID
   * @param locationId The location ID
   * @returns A list of payment methods
   */
  static async getV2PaymentMethods(accountId: string, locationId: string) {
    const results = await httpGetJson<DapiSingleResult<V2PaymentMethod[]>>(
      getV2PaymentMethodsUrl(accountId, locationId)
    );
    return results.data;
  }

  /**
   * Get payment methods for v1 Solv Pay.
   * @param accountId The user account ID
   * @returns List of payment methods
   */
  static async getV1PaymentMethods(accountId: string) {
    const results = await httpGetJson<DapiDataResults<V1PaymentMethod>>(
      getPaymentCardUrl(accountId)
    );

    return results.data.results;
  }

  /**
   * Given a payment method ID that we have access to (likely one that's newly been created or
   * entered), update its metadata in stripe to associate it with a particular location.
   * @param paymentMethodId The stripe Payment Method ID (looks like pm_[id])
   * @param locationId The ID of the location to associate this payment method with
   * @param cardType Whether this is a card, HSA/FSA, or other type of payment method
   * @returns
   */
  static async associatePaymentMethodWithLocation(
    paymentMethodId: string,
    locationId: string,
    cardType: 'card' | 'hsa-fsa' | undefined = 'card'
  ) {
    const result = await httpGetJson<DapiSingleResult<any>>(
      `${DAPI_CACTUS_HOST}/v2/payment-customers/associate-payment-method-with-location?${new URLSearchParams(
        {
          payment_method_id: paymentMethodId,
          location_id: locationId,
          card_type: cardType,
        }
      )}`
    );
    return result.data;
  }

  /**
   * Issue a charge from the POS/Face sheet for a specific booking, payment intent, amount, and reason.
   * @param args.bookingId The booking ID
   * @param args.amountCents The amount to charge, in cents
   * @param args.paymentMethodId The payment method ID to charge
   * @param args.reason The reason for the charge
   * @returns The updated `charge` object
   */
  static async postV2PosCharge({
    bookingId,
    amountCents,
    paymentMethodId,
    reason,
  }: {
    bookingId: string;
    amountCents: number;
    paymentMethodId: string;
    reason: string;
  }) {
    return await httpPostJson<DapiSingleResult<any>>(getV2ChargesPOSUrl(), {
      booking_id: bookingId,
      amount_cents: amountCents,
      payment_method_id: paymentMethodId,
      reason: reason,
    });
  }

  /**
   * Creates an off-session setup intent for a given account ID.
   * @param accountId The user account ID
   * @returns The setup intent response and client secret
   */
  static async createSetupIntent(accountId: string) {
    const result = await httpPostJson<DapiSingleResult<SetupIntentResponse>>(
      `${DAPI_CACTUS_HOST}/v2/charges/setup-intent/${accountId}`,
      {}
    );
    return result.data;
  }

  /**
   * Get list of charges for a given booking ID (that are not pending).
   * @param bookingId The booking ID
   * @returns A list of charges
   */
  static async getV1Charges(bookingId: string) {
    const response = await httpGetJson<DapiDataResults<V1Charge>>(
      getAllChargesByBookingUrl(bookingId)
    );
    const filtered = response.data.results.filter((charge) => charge.status !== 'pending');
    const sorted = sortByTimestamps(filtered, (charge) => charge.created_date, SORT_DESC);
    return sorted;
  }
}
