import { produce } from 'immer';
import {
  LOGIN_LOGOUT,
  REVIEWS_CLEAR,
  REVIEWS_LIST_RECEIVED,
  REVIEWS_TO_MODERATE_ERROR,
  SET_REVIEWS_MODERATION_TAB,
  REVIEW_RECEIVED,
  REVIEW_UPDATED_IN_SEARCH_RESULTS,
} from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';
import { convertSnakeToCamelCaseRecursive, isEmptyObject } from '../core/util/object';

const setReviewsModerationTab = (value) => ({
  type: SET_REVIEWS_MODERATION_TAB,
  payload: { value },
});

const receiveReviewsList = (value) => ({
  type: REVIEWS_LIST_RECEIVED,
  payload: { value },
});

const receiveReview = (value) => ({
  type: REVIEW_RECEIVED,
  payload: { value },
});

const reviewUpdateInSearchResults = (value) => ({
  type: REVIEW_UPDATED_IN_SEARCH_RESULTS,
  payload: { value },
});

const clearReviews = (value) => ({
  type: REVIEWS_CLEAR,
  payload: { value },
});

const reviewsToModerateError = (value) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: REVIEWS_TO_MODERATE_ERROR,
    payload: { value },
  };
};

const createInitialState = () => ({
  data: EMPTY_ARRAY,
  noMoreReviews: false,
  currentBooking: {},
});

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case SET_REVIEWS_MODERATION_TAB:
      return {
        ...state,
        currentTab: action.payload.value,
      };
    case LOGIN_LOGOUT:
      return createInitialState();
    case REVIEWS_LIST_RECEIVED:
      return {
        ...state,
        results: convertSnakeToCamelCaseRecursive(action.payload.value.results),
        total: action.payload.value.page.results_count,
      };
    case REVIEW_RECEIVED:
      return {
        ...state,
        results: [convertSnakeToCamelCaseRecursive(action.payload.value)],
        total: isEmptyObject(action.payload.value) ? 0 : 1,
      };
    case REVIEW_UPDATED_IN_SEARCH_RESULTS: {
      const updatedReview = convertSnakeToCamelCaseRecursive(action.payload.value);
      return produce(state, (draft) => {
        const results = draft.results;
        results.splice(
          results.findIndex((review) => review.id === updatedReview.id),
          1,
          updatedReview
        );
      });
    }
    case REVIEWS_CLEAR:
      return {
        ...state,
        data: [],
        results: [],
        total: 0,
        noMoreReviews: false,
      };
    default:
      return state;
  }
};

export {
  reducer as default,
  setReviewsModerationTab,
  reviewsToModerateError,
  clearReviews,
  receiveReviewsList,
  receiveReview,
  reviewUpdateInSearchResults,
};
