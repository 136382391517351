import moment from 'moment';
import { DAPI_HOST } from '../../config';
import { apiGetDispatchable, apiPostDispatchable, apiPatchDispatchable } from './index';
import { dateFormat } from '../util/date';
import { getLocationUrl } from './location';
import { receiveLocation, locationError } from '../../actions/location';
import { isEmptyString } from '../util/string';
import {
  sanitizeDateString,
  sanitizeMaskedBirthDateString,
} from '../../components/Form/BirthDateInput';
import {
  BOOKING_STATUS_RESERVED,
  BOOKING_STATUS_CONFIRMED,
  ORIGIN_KIOSK_SIGN_IN,
  ORIGIN_QUEUE,
  LANG_ENG,
  WALK_IN,
  BOOKING_IS_NOT_EXISTING_PATIENT,
  AUTHOR_TYPE_CLINIC_ACCOUNT,
  TELEMED_IN_PROGRESS,
  EMPTY_STRING,
  STRING_TRUE,
  PREFERRED_TIME_ASAP,
} from '../../constants';
import { getClinicAccountId } from '../auth';
import { getNearestSlotAvailableToday } from '../location/slots';
import { normalizePhone } from '../util/phoneNumber';
import { getPresentationMode } from '../../routes/welcome/presentationMode';
import { GenericObject } from '../util/generics';
import { Booking, Location } from '../../types/RootState';
import { LocationV2 } from '../../types/dapi/Location';

const getNewBookingUrl = () => `${DAPI_HOST}/v1/registration/bookings`;

const normalizePhoneForKiosk = (phoneObj: { phone: string }) => {
  const phonestr = phoneObj.phone.toString();
  let sanitizedPhone = phonestr.replace(/\D/g, '');
  sanitizedPhone = sanitizedPhone.replace(/^1/, '');
  if (sanitizedPhone.length === 10) {
    sanitizedPhone = `+1${sanitizedPhone}`;
  }

  return sanitizedPhone;
};

export interface CreateBookingRequest {
  firstName: string | null;
  lastName: string | null;
  phone: string;
  birthDate: string | null;
  email: string | null;
  reasonForVisit: string;
  origin: string;
  locationId: string;
  appointmentDate: string;
  isExistingPatient: boolean;
  status: string;
  isCallAhead: boolean | null;
  language: string | null;
  userProfileId: string | null;
  smsConsent: boolean;
  isCallCenter: boolean;
  isPriority: boolean;
  isTelemed: boolean;
  isValidPhone: boolean | null;
  tosConsent: boolean | null;
  birthSex: string | null;
  preferredTime: string | null;
  checkInSource: string | null;
  insuranceProfileId: string | null;
  addressStreet: string | null;
  addressStreetSecondary: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
}

const buildBookingPostData = ({
  firstName = null,
  lastName = null,
  phone = '',
  birthDate = null,
  email = null,
  reasonForVisit = '',
  origin = '',
  locationId = '',
  appointmentDate = '',
  isExistingPatient = false,
  status = '',
  isCallAhead = null,
  language = null,
  userProfileId = null,
  smsConsent = true,
  isCallCenter = false,
  isPriority = false,
  isTelemed = false,
  isValidPhone = null,
  tosConsent = null,
  birthSex = null,
  preferredTime = null,
  checkInSource = null,
  insuranceProfileId = null,
  addressStreet = null,
  addressStreetSecondary = null,
  city = null,
  state = null,
  zipcode = null,
}: CreateBookingRequest) => {
  const postData: GenericObject = {
    account: { phone, tos_consent: tosConsent },
    user_profile: [addressStreet, addressStreetSecondary, city, state, zipcode].every(
      (x) => x == null
    )
      ? {}
      : {
          address_street: addressStreet,
          address_street_secondary: addressStreetSecondary,
          address_city: city,
          address_state: state,
          address_zip: zipcode,
        },
    booking: {
      first_name: firstName,
      last_name: lastName,
      phone,
      reason_for_visit: reasonForVisit,
      origin,
      location_id: locationId,
      appointment_date: appointmentDate,
      is_existing_patient: isExistingPatient,
      is_call_ahead: isCallAhead,
      sms_consent: smsConsent,
      status,
      language: language,
      is_priority: isPriority,
      check_in_source: checkInSource,
      insurance_profile_id: insuranceProfileId,
    },
  };

  if (isValidPhone === true || isValidPhone === false) {
    postData.account.is_valid_phone = isValidPhone;
  }

  if (isTelemed) {
    postData.booking.telemed_status = TELEMED_IN_PROGRESS;
    postData.booking.preferred_time = preferredTime;
  }

  if (getClinicAccountId()) {
    postData.booking.author_type = AUTHOR_TYPE_CLINIC_ACCOUNT;
    postData.booking.author_id = getClinicAccountId();
  }

  const userProfileInfo = { ...postData.user_profile };

  if (firstName) {
    postData.account.first_name = firstName;
    userProfileInfo.first_name = firstName;
  }

  if (lastName) {
    postData.account.last_name = lastName;
    userProfileInfo.last_name = lastName;
  }

  if (userProfileId) {
    userProfileInfo.user_profile_id = userProfileId;
  }

  postData.user_profile = userProfileInfo;

  if (birthDate) {
    postData.user_profile.birth_date = birthDate;
  }

  if (birthSex) {
    postData.user_profile.birth_sex = birthSex;
  }

  if (email) {
    postData.booking.email = email;
    postData.account.email = email;
  }

  return postData;
};

const buildSignInPostData = (props: {
  location: Location;
  newBooking: { booking: Booking; profile: any; language: string };
}) => {
  const { location, newBooking } = props;
  const { booking, profile } = newBooking;
  const phone = normalizePhone(profile.phone);

  const appointmentDate =
    booking.appointmentDate || getNearestSlotAvailableToday(location, Date.now());

  const email = profile.email || null;
  const language = !isEmptyString(newBooking.language) ? newBooking.language : LANG_ENG;

  const isCallAhead = false;
  const smsConsent = true;
  const isCallCenter = false;
  const isPriority = false;
  const isTelemed = false;
  const isValidPhone = null;
  const tosConsent = true;

  return buildBookingPostData({
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone,
    birthDate: profile.birthDate,
    email,
    reasonForVisit: booking.reasonForVisit,
    origin: ORIGIN_KIOSK_SIGN_IN,
    locationId: location.id,
    appointmentDate: dateFormat(appointmentDate, 'isoUtcDateTime'),
    isExistingPatient: booking.isExistingPatient,
    status: BOOKING_STATUS_CONFIRMED,
    isCallAhead,
    language,
    userProfileId: profile.userProfileId,
    smsConsent,
    isCallCenter,
    isPriority,
    isTelemed,
    isValidPhone,
    tosConsent,
    birthSex: profile.birthSex,
    preferredTime: booking.preferredTime,
    checkInSource: booking.checkInSource,
    addressStreet: null,
    addressStreetSecondary: null,
    city: null,
    insuranceProfileId: null,
    state: null,
    zipcode: null,
  });
};

const buildDataForHasAccountBooking = (
  location: Location,
  newBooking: { booking: Booking },
  userInfo: any,
  paperwork: any
) => ({
  location,
  newBooking: {
    ...newBooking,
    booking: {
      appointmentDate: userInfo.appointmentDate,
      isExistingPatient: BOOKING_IS_NOT_EXISTING_PATIENT,
      reasonForVisit: userInfo.reason,
      checkInSource: getPresentationMode(),
    },
    profile: {
      phone: newBooking.booking.phone,
      userProfileId: userInfo.userProfileId,
    },
  },
  paperwork,
});

const buildPostRegistrationBookingsData = (
  form: GenericObject,
  locationId: string,
  selectedDate: string
) => {
  const phone = normalizePhone(form.mobilePhone.phone);

  let appointmentDate = null;
  if (form.appointmentDateAndTime) {
    appointmentDate = form.appointmentDateAndTime.toISOString();
  } else {
    appointmentDate = selectedDate;
  }

  let birthDate = null;
  if (form.dateOfBirth) {
    birthDate = sanitizeMaskedBirthDateString(form.dateOfBirth.birthDate);
  }

  const isExistingPatient = form.patientType;
  const isCallAhead =
    form.reservationType !== WALK_IN || form.preferredTime === PREFERRED_TIME_ASAP;
  const smsConsent = true;
  const isCallCenter = form.isCallCenter;
  const userProfileId = null;
  const isPriority = form.isPriority;
  const isTelemed = form.isTelemed;
  const isValidPhone = form.isValidPhone;
  const preferredTime = form.preferredTime;
  const checkInSource = form.checkInSource;
  const insuranceProfileId = form.insuranceProfileId || null;
  const language = form.language;

  let status;
  if (isCallAhead) {
    status = BOOKING_STATUS_RESERVED;
  } else {
    status = BOOKING_STATUS_CONFIRMED;
  }

  return buildBookingPostData({
    firstName: form.firstName,
    lastName: form.lastName,
    phone,
    birthDate,
    email: form.emailAddress,
    reasonForVisit: form.reasonForVisit,
    origin: ORIGIN_QUEUE,
    locationId,
    appointmentDate,
    isExistingPatient,
    status,
    isCallAhead,
    language,
    userProfileId,
    smsConsent,
    isCallCenter,
    isPriority,
    isTelemed,
    isValidPhone,
    tosConsent: null,
    birthSex: form.birthSex,
    preferredTime,
    checkInSource,
    insuranceProfileId,
    addressStreet: form.addressStreet,
    addressStreetSecondary: form.addressStreetSecondary,
    city: form.city,
    state: form.state,
    zipcode: form.zipcode,
  });
};

const apiPatchBookingDispatchable =
  (
    url: string,
    patchData: any,
    onSuccess: (args: any) => any,
    onFailure: (args: any) => any,
    locationId: string
  ) =>
  (dispatch: any) => {
    const handleSuccess = (response: any) => {
      if (locationId) {
        dispatch(apiGetDispatchable(getLocationUrl(locationId), receiveLocation, locationError));
      }

      return onSuccess(response);
    };

    dispatch(apiPatchDispatchable(url, patchData, handleSuccess, onFailure, ORIGIN_QUEUE));
  };

const apiPostBookingDispatchable =
  (
    url: string,
    postData: any,
    onSuccess: (args: any) => any,
    onFailure: (args: any) => any,
    locationId?: string
  ) =>
  (dispatch: any) => {
    const handleSuccess = (response: any) => {
      if (locationId) {
        dispatch(apiGetDispatchable(getLocationUrl(locationId), receiveLocation, locationError));
      }

      return onSuccess(response);
    };

    dispatch(apiPostDispatchable(url, postData, handleSuccess, onFailure));
  };

const buildPatchBookingsData = (formData: GenericObject) => {
  const patchData: GenericObject = {};

  for (const field in formData) {
    if (formData.hasOwnProperty(field)) {
      switch (field) {
        case 'firstName':
          patchData.first_name = formData[field];
          break;
        case 'lastName':
          patchData.last_name = formData[field];
          break;
        case 'mobilePhone':
          patchData.phone = normalizePhone(formData[field].phone);
          break;
        case 'emailAddress':
          patchData.email = formData[field];
          break;
        case 'patientType':
          patchData.is_existing_patient = formData.patientType;
          break;
        case 'reservationType':
          patchData.is_call_ahead = formData[field] !== WALK_IN;
          break;
        case 'reasonForVisit':
          patchData.reason = formData[field];
          break;
        case 'reasonForCancellation':
          patchData.cancellation_reason = formData[field];
          break;
        case 'notes':
          patchData.notes = formData[field];
          break;
        case 'status':
          patchData.status = formData[field];
          break;
        case 'authorType':
          patchData.author_type = formData.authorType;
          break;
        case 'authorId':
          patchData.author_id = formData.authorId;
          break;
        case 'isPriority':
          patchData.is_priority = formData[field];
          break;
        case 'hasBeenTriaged':
          if (formData[field]) {
            patchData.triaged_timestamp = moment.tz().toISOString();
          } else {
            patchData.triaged_timestamp = null;
          }
          break;
        case 'isPaperworkBypassed':
          patchData.is_paperwork_bypassed = formData[field];
          break;
        case 'isPaymentBypassed':
          patchData.is_payment_bypassed = formData[field];
          break;
        case 'isTelemed':
          patchData.telemed_status = formData[field] === true ? TELEMED_IN_PROGRESS : EMPTY_STRING;
          break;
        case 'uberRideStatus':
          patchData.uber_ride_status = formData[field];
          break;
        case 'language':
          patchData.language = formData[field];
          break;
        default:
          break;
      }
    }
  }

  return patchData;
};

const buildPatchAccountData = (formData: GenericObject) => {
  const patchData: GenericObject = {};
  for (const field in formData) {
    if (
      formData.hasOwnProperty(field) &&
      formData[field] !== undefined &&
      formData[field] !== null
    ) {
      switch (field) {
        case 'smsConsent':
          patchData.sms_consent = formData[field] === STRING_TRUE;
          break;
        case 'address':
          patchData.address = formData[field];
          break;
        case 'city':
          patchData.city = formData[field];
          break;
        case 'state':
          patchData.state = formData[field].toUpperCase();
          break;
        case 'zipCode':
          patchData.zip_code = formData[field];
          break;
        default:
          break;
      }
    }
  }

  return patchData;
};

const buildPatchUserProfileData = (formData: GenericObject) => {
  const patchData: GenericObject = {};
  Object.entries(formData).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      switch (key) {
        case 'firstName':
          patchData.first_name = value;
          break;
        case 'lastName':
          patchData.last_name = value;
          break;
        case 'mobilePhone':
          patchData.phone_primary = normalizePhone(value.phone);
          break;
        case 'emailAddress':
          patchData.email = value;
          break;
        case 'dateOfBirth':
          patchData.birth_date = sanitizeDateString(value);
          break;
        case 'gender':
          patchData.gender = value.toLowerCase();
          break;
        case 'birthSex':
          patchData.birth_sex = value.toLowerCase();
          break;
        case 'pharmacy':
          patchData.pharmacy = value;
          break;
        case 'address':
          patchData.address_street = value;
          break;
        case 'addressStreetSecondary':
          patchData.address_street_secondary = value;
          break;
        case 'city':
          patchData.address_city = value;
          break;
        case 'state':
          patchData.address_state = value.toUpperCase();
          break;
        case 'zipCode':
          patchData.address_zip = value;
          break;
        case 'race':
          patchData.race = value;
          break;
        case 'ethnicity':
          patchData.ethnicity = value;
          break;
        default:
          break;
      }
    }
  });

  return patchData;
};

const isBirthSexRequired = (location: Location | Pick<LocationV2, 'isBirthSexRequired'>) =>
  location && 'is_birth_sex_required' in location
    ? location.is_birth_sex_required
    : location.isBirthSexRequired;

export {
  getNewBookingUrl,
  apiPatchBookingDispatchable,
  apiPostBookingDispatchable,
  buildBookingPostData,
  buildPostRegistrationBookingsData,
  buildPatchBookingsData,
  buildPatchUserProfileData,
  buildPatchAccountData,
  normalizePhoneForKiosk,
  normalizePhone,
  buildSignInPostData,
  buildDataForHasAccountBooking,
  isBirthSexRequired,
};
