import { useForceHttps } from '../hooks/useForceHttps';
import { useHeartBeat } from '../hooks/useHeartBeat';
import { useEffect } from 'react';
import { analyticsTrackEvent } from '../core/analytics';
import { MANAGE_LOADED } from '../core/analytics/events';
import { useSentry } from '../hooks/useSentry';

export default function Global() {
  useForceHttps();
  useSentry();
  useHeartBeat();

  useEffect(() => {
    analyticsTrackEvent(MANAGE_LOADED, {});
  }, []);

  return null;
}
