import {
  CURRENT_PATIENT_ELIGIBLIITY_REQUEST_ID_RECEIVED,
  CURRENT_PATIENT_PAYMENTS_INSURANCE_ERROR,
  CURRENT_PATIENT_PAYMENTS_INSURANCE_RECEIVED,
  GET_CURRENT_PATIENT_ACCOUNT_SUMMARY,
  GET_CURRENT_PATIENT_ACCOUNT_SUMMARY_ERROR,
  GET_CURRENT_PATIENT_BOOKING_DETAILS,
  GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_ERROR,
  GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_ERROR,
  GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_INSURANCE_PROFILE_ERROR,
  GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS,
  GET_CURRENT_PATIENT_USER_PROFILE_ERROR,
  GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS,
  RESET_CURRENT_PATIENT,
  SET_CURRENT_PATIENT_BOOKING_SUCCESS,
  CURRENT_PATIENT_INSURANCE_SUMMARY_CLEAR,
  SET_CURRENT_PATIENT_BOOKING_ERROR,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  CURRENT_PATIENT_RECEIVE_CONSENT_FORM_SIGNATURES,
  CURRENT_PATIENT_CONSENT_FORM_SIGNATURES_ERROR,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_URL,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_URL,
  CURRENT_PATIENT_PHOTO_ID_URL_ERROR,
} from '../constants';

const resetCurrentPatient = () => ({ type: RESET_CURRENT_PATIENT });

const eligibilityRequestIdReceived = (value) => ({
  type: CURRENT_PATIENT_ELIGIBLIITY_REQUEST_ID_RECEIVED,
  payload: { value },
});

const paymentsInsuranceReceived = (value) => ({
  type: CURRENT_PATIENT_PAYMENTS_INSURANCE_RECEIVED,
  payload: { value },
});

const paymentsInsuranceError = (value) => ({
  type: CURRENT_PATIENT_PAYMENTS_INSURANCE_ERROR,
  payload: { value },
});

const setCurrentPatientBookingSuccess = (value) => ({
  type: SET_CURRENT_PATIENT_BOOKING_SUCCESS,
  payload: { value },
});

const setCurrentPatientBookingError = (value) => ({
  type: SET_CURRENT_PATIENT_BOOKING_ERROR,
  payload: { value },
});

const getCurrentPatientUserProfileSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS,
  payload: { value },
});

const getCurrentPatientUserProfileError = (value) => ({
  type: GET_CURRENT_PATIENT_USER_PROFILE_ERROR,
  payload: { value },
});

const getCurrentPatientInsuranceProfileSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS,
  payload: { value },
});

const getCurrentPatientInsuranceProfileError = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_PROFILE_ERROR,
  payload: { value },
});

const getCurrentPatientDentalInsuranceProfileSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS,
  payload: { value },
});

const getInsuranceProfileImageFrontSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  payload: { value },
});

const getDentalInsuranceProfileImageFrontSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  payload: { value },
});

const getDentalInsuranceProfileImageBackSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  payload: { value },
});

const getInsuranceProfileImageBackSuccess = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  payload: { value },
});

const getInsuranceProfileImageFrontError = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_ERROR,
  payload: { value },
});

const getInsuranceProfileImageBackError = (value) => ({
  type: GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_ERROR,
  payload: { value },
});

const getCurrentPatientAccountSummary = (value) => ({
  type: GET_CURRENT_PATIENT_ACCOUNT_SUMMARY,
  payload: { value },
});

const getCurrentPatientAccountSummaryError = (value) => ({
  type: GET_CURRENT_PATIENT_ACCOUNT_SUMMARY_ERROR,
  payload: { value },
});

const getCurrentPatientBookingDetails = (value) => ({
  type: GET_CURRENT_PATIENT_BOOKING_DETAILS,
  payload: { value },
});

const clearInsuranceSummary = (value) => ({
  type: CURRENT_PATIENT_INSURANCE_SUMMARY_CLEAR,
  payload: { value },
});

const receiveConsentFormSignatures = (value) => ({
  type: CURRENT_PATIENT_RECEIVE_CONSENT_FORM_SIGNATURES,
  payload: { value },
});

const consentFormSignaturesError = (value) => ({
  type: CURRENT_PATIENT_CONSENT_FORM_SIGNATURES_ERROR,
  payload: { value },
});

const receivePhotoIdFrontUrl = (value) => ({
  type: CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_URL,
  payload: { value },
});

const receivePhotoIdBackUrl = (value) => ({
  type: CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_URL,
  payload: { value },
});

const photoIdUrlError = (value) => ({
  type: CURRENT_PATIENT_PHOTO_ID_URL_ERROR,
  payload: { value },
});

export {
  eligibilityRequestIdReceived,
  resetCurrentPatient,
  setCurrentPatientBookingSuccess,
  setCurrentPatientBookingError,
  getCurrentPatientUserProfileSuccess,
  getCurrentPatientUserProfileError,
  getCurrentPatientInsuranceProfileSuccess,
  getCurrentPatientInsuranceProfileError,
  getInsuranceProfileImageFrontSuccess,
  getInsuranceProfileImageFrontError,
  getInsuranceProfileImageBackSuccess,
  getInsuranceProfileImageBackError,
  getCurrentPatientAccountSummary,
  getCurrentPatientAccountSummaryError,
  getCurrentPatientBookingDetails,
  paymentsInsuranceError,
  paymentsInsuranceReceived,
  clearInsuranceSummary,
  getCurrentPatientDentalInsuranceProfileSuccess,
  getDentalInsuranceProfileImageFrontSuccess,
  getDentalInsuranceProfileImageBackSuccess,
  receiveConsentFormSignatures,
  consentFormSignaturesError,
  receivePhotoIdFrontUrl,
  receivePhotoIdBackUrl,
  photoIdUrlError,
};
