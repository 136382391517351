import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSolvEmployee } from '../../core/session';
import { getLocationsFromIds } from '../../core/util/location';
import LocationsSelector from '../Form/LocationsSelector';
import { getQueueSelectedLocationIds } from '../../selectors/queue';
import { getLogin } from '../../selectors/login';

const ModalLocationPicker = props => {
  const {
    login,
    selectedLocationIds,
    handleSelectLocation,
    currentModalLocationId,
    showAllLocations,
    className,
  } = props;

  const locationOptions = showAllLocations
    ? login.account.locations
    : getLocationsFromIds(login.account.locations, selectedLocationIds);
  return (
    <LocationsSelector
      defaultSelectedLocation={currentModalLocationId}
      isSolvEmployee={isSolvEmployee(login)}
      locationOptions={locationOptions}
      shouldShowTitle={false}
      onLocationSelectValueChange={handleSelectLocation}
      className={className}
    />
  );
};

ModalLocationPicker.propTypes = {
  login: PropTypes.object,
  selectedLocationIds: PropTypes.array,
  handleSelectLocation: PropTypes.func,
  currentModalLocationId: PropTypes.string,
  showAllLocations: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  login: getLogin(state),
  selectedLocationIds: Array.isArray(ownProps.selectedLocationIds)
    ? ownProps.selectedLocationIds
    : getQueueSelectedLocationIds(state),
});

export default connect(mapStateToProps)(ModalLocationPicker);
