import { call, put, takeEvery } from 'redux-saga/effects';
import { apiDelete, apiPatch, apiPost } from '../../core/dapi';
import {
  getServicePreferencesUrl,
  createServicePreferencesUrl,
  updateServicePreferencesUrl,
} from '../../core/dapi/servicePreferences';
import {
  servicePreferencesError,
  servicePreferencesLoading,
  servicePreferencesReceived,
  servicePreferencesUpdated,
} from '../../ducks/servicePreferences';
import { apiGetAllPages } from './base';

export class ServicePreferencesSagas {
  static FETCH_SERVICE_PREFERENCES = 'saga/FETCH_SERVICE_PREFERENCES';
  static UPDATE_SERVICE_PREFERENCE = 'saga/UPDATE_SERVICE_PREFERENCE';
  static CREATE_SERVICE_PREFERENCE = 'saga/CREATE_SERVICE_PREFERENCE';
  static DELETE_SERVICE_PREFERENCE = 'saga/DELETE_SERVICE_PREFERENCE';
}

export function* getServicePreferences({ locationId, serviceId }) {
  yield put(servicePreferencesLoading(true));

  try {
    const response = yield call(apiGetAllPages, {
      url: getServicePreferencesUrl(locationId, serviceId),
    });
    yield put(servicePreferencesReceived(response.results));
  } catch (e) {
    yield put(servicePreferencesError(e));
  } finally {
    yield put(servicePreferencesLoading(false));
  }
}

export function* createServicePreference({ onSuccess, onError, postData }) {
  yield put(servicePreferencesLoading(true));

  try {
    const response = yield call(apiPost, createServicePreferencesUrl(), postData);
    yield put(servicePreferencesUpdated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(servicePreferencesError(e));
    if (typeof onError === 'function') onError(e);
  } finally {
    yield put(servicePreferencesLoading(false));
  }
}

export function* updateServicePreference({ onSuccess, onError, patchData, servicePreferenceId }) {
  try {
    const response = yield call(
      apiPatch,
      updateServicePreferencesUrl(servicePreferenceId),
      patchData
    );
    yield put(servicePreferencesUpdated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(servicePreferencesError(e));
    if (typeof onError === 'function') onError(e);
  } finally {
    yield put(servicePreferencesLoading(false));
  }
}

export function* deleteServicePreference({
  onSuccess,
  onError,
  servicePreferenceId,
  locationId,
  serviceId,
}) {
  try {
    const deletedRows = yield call(apiDelete, updateServicePreferencesUrl(servicePreferenceId), {});
    if (typeof onSuccess === 'function') onSuccess();
    const preferences = yield call(apiGetAllPages, {
      url: getServicePreferencesUrl(locationId, serviceId),
    });
    yield put(servicePreferencesReceived(preferences.results));
  } catch (e) {
    yield put(servicePreferencesError(e));
    if (typeof onError === 'function') onError(e);
  } finally {
    yield put(servicePreferencesLoading(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(ServicePreferencesSagas.FETCH_SERVICE_PREFERENCES, getServicePreferences);
  yield takeEvery(ServicePreferencesSagas.UPDATE_SERVICE_PREFERENCE, updateServicePreference);
  yield takeEvery(ServicePreferencesSagas.CREATE_SERVICE_PREFERENCE, createServicePreference);
  yield takeEvery(ServicePreferencesSagas.DELETE_SERVICE_PREFERENCE, deleteServicePreference);
}
