import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { BookingStatus } from '../../constants';
import { isEmptyString } from '../util/string';
import { isDev } from '../../config/';
import { Location, LocationUnformatted } from '../../types/RootState';
import { isLocationV2, LocationV2 } from '../../types/dapi/Location';
import isEmpty from 'lodash/isEmpty';

export enum GAEventType {
  GA_EVENT = 'event',
}

export enum GAEventCategory {
  BOOKING = 'booking',
  WORKFLOW = 'workflow',
}

export enum GAEventAction {
  SUBMIT_QUEUE_WALK_IN = 'submit-queue-walk-in',
  SUBMIT_QUEUE_CALL_AHEAD = 'submit-queue-call-ahead',
  SUBMIT_KIOSK_WALK_IN = 'submit-kiosk-walk-in',
  CHECK_IN_KIOSK = 'check-in-kiosk',
  CHECK_IN_QUEUE = 'check-in-queue',
  STATUS_TO_RESERVED = 'status-change-reserved',
  STATUS_TO_CONFIRMED = 'status-change-confirmed',
  STATUS_TO_CHECKED_IN = 'status-change-checked_in',
  STATUS_TO_IN_EXAM_ROOM = 'status-change-in_exam_room',
  STATUS_TO_DISCHARGED = 'status-change-discharged',
  STATUS_TO_CANCELLED = 'status-change-cancelled',
  STATUS_TO_NO_SHOW = 'status-change-no_show',
  STATUS_TO_REJECTED = 'status-change-rejected',
  BOOKING_CREATED = 'booking-created',
}

export const STATUS_TO_EVENT_MAP = new Map<BookingStatus, GAEventAction>([
  [BookingStatus.Reserved, GAEventAction.STATUS_TO_RESERVED],
  [BookingStatus.Confirmed, GAEventAction.STATUS_TO_CONFIRMED],
  [BookingStatus.CheckedIn, GAEventAction.STATUS_TO_CHECKED_IN],
  [BookingStatus.InExam, GAEventAction.STATUS_TO_IN_EXAM_ROOM],
  [BookingStatus.Discharged, GAEventAction.STATUS_TO_DISCHARGED],
  [BookingStatus.Cancelled, GAEventAction.STATUS_TO_CANCELLED],
  [BookingStatus.NoShow, GAEventAction.STATUS_TO_NO_SHOW],
  [BookingStatus.Rejected, GAEventAction.STATUS_TO_REJECTED],
]);

type TrackingPropertiesGA4 = {
  isGA4: true;
  trackerId: string;
};

type TrackingPropertiesUA = {
  isGA4: false;
  trackerId: string;
  trackerName: string;
  forceSsl: boolean;
  referringDomain: string;
};

interface GATrackingEvent {
  eventAction: GAEventAction | '';
  eventCategory?: string;
  eventType?: GAEventType;
  eventLabel?: string;
}

/**
 * Right now, this is supporting a boolean property in the trackingProperties parameter for isGA4,
 * and based on that will track the events for GA4 or Universal Analytics.
 * UA is being deprecated July 1, 2023, so we can probably rip out all UA code after that point.
 */
const fireGaTracking = ({
  trackingProperties,
  trackingEvent,
}: {
  trackingProperties: TrackingPropertiesGA4 | TrackingPropertiesUA;
  trackingEvent: GATrackingEvent;
}) => {
  const { eventType, eventAction, eventCategory, eventLabel } = trackingEvent;
  if (eventType === GAEventType.GA_EVENT && (!eventAction || !eventCategory)) {
    throw new Error('Need to pass both eventAction and eventCategory for event tracking');
  }

  if (trackingProperties.isGA4) {
    const { trackerId } = trackingProperties;

    if (window) {
      /**
       * This property is set to true in the callback of ReactGA4.event in order to avoid sending automatic pageviews
       * and other automatic events after GA4 has been initialized. Set to false here before sending the event so it
       * makes it through to the partner's GA4 account
       */
      // @ts-ignore
      window[`ga-disable-${trackerId}`] = false;
    }
    ReactGA4.initialize(trackerId, { gtagOptions: { send_page_view: false, groups: 'partner' } });
    /**
     * ReactGA4's .event automatically title cases the event action if you use call it with ReactGA.event(options),
     * using ReactGA.event(name, params) does not. All of our events are historically not title-cased,
     * so we use the latter approach.
     */
    ReactGA4.event(eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      send_to: 'partner',
      event_callback: (containerId: string) => {
        if (containerId.startsWith(trackerId)) {
          // @ts-ignore
          window[`ga-disable-${trackerId}`] = true;
        }
      },
    });
  } else {
    const { trackerName, trackerId, forceSsl, referringDomain } = trackingProperties;
    const gaOptions = {
      allowLinker: true,
      alwaysSendReferrer: true,
      cookieDomain: 'auto',
      name: trackerName,
    };

    ReactGA.initialize(trackerId, { gaOptions });

    if (forceSsl) {
      ReactGA.ga(`${trackerName}.set`, 'forceSSL', true);
    }

    ReactGA.ga(`${trackerName}.require`, 'linker');

    if (referringDomain) {
      ReactGA.ga(`${trackerName}.linker:autoLink`, [referringDomain]);
    }
    ReactGA.ga(`${trackerName}.send`, eventType, eventCategory, eventAction, eventLabel);
  }
};

const fireGoogleAnalytics = (
  location: (LocationUnformatted & Location) | Pick<LocationV2, 'trackingProperties' | 'id'>,
  eventAction: GAEventAction | '',
  eventType: GAEventType = GAEventType.GA_EVENT,
  eventCategory: GAEventCategory,
  eventLabelOrBooking: string | { id: string }
) => {
  let eventLabel;
  if (typeof eventLabelOrBooking === 'string') {
    eventLabel = eventLabelOrBooking;
  } else {
    eventLabel = eventLabelOrBooking?.id;
  }

  if (isDev()) {
    console.warn(
      'Hello developer,\nPlease refer to this GA Tracking for Partners documentation and help keep it up to date: https://docs.google.com/document/d/1j_nHRZLgJ94DEI-UA8uXO6r7iaCIB0IEQxcOQIxPsBI/edit?usp=sharing\n<3 gusbot1.0'
    );

    console.warn('Google Analytics Tracking cannot be fired in dev. exiting.');
    return;
  }

  const analytics = isLocationV2(location)
    ? location.trackingProperties?.googleAnalytics || location.trackingProperties?.googleAnalytics4
    : location.trackingProperties?.google_analytics ||
      location.trackingProperties?.google_analytics4;
  if (!analytics) {
    console.warn(`Could not fire tracking! 
      Tracking properties for location ${location.id} are not defined`);
    return;
  }

  let trackingEvent = { eventType } as GATrackingEvent;
  if (eventType === GAEventType.GA_EVENT) {
    if (isEmptyString(eventAction) || isEmptyString(eventCategory)) {
      console.error(`Could not fire tracking! Tracking properties eventAction
       and eventCategory need to be specified for a GA_EVENT`);
      return;
    }

    trackingEvent = {
      ...trackingEvent,
      eventAction,
      eventCategory,
      eventLabel,
    };
  }

  let ga4Properties = null;
  let uaProperties = null;
  if (isLocationV2(location)) {
    if (!isEmpty(location.trackingProperties.googleAnalytics)) {
      uaProperties = {
        trackerName: location.trackingProperties.googleAnalytics.trackerName,
        trackerId: location.trackingProperties.googleAnalytics.id,
        referringDomain: location.trackingProperties.googleAnalytics.referringDomain,
        forceSsl: location.trackingProperties.googleAnalytics.forceSsl ?? false,
        isGA4: false,
      } as TrackingPropertiesUA;
    }
    if (!isEmpty(location.trackingProperties.googleAnalytics4)) {
      ga4Properties = {
        trackerId: location.trackingProperties.googleAnalytics4.id,
        isGA4: true,
      } as TrackingPropertiesGA4;
    }
  } else {
    if (!isEmpty(location.trackingProperties.google_analytics)) {
      uaProperties = {
        trackerName: location.trackingProperties.google_analytics.tracker_name,
        trackerId: location.trackingProperties.google_analytics.id,
        referringDomain: location.trackingProperties.google_analytics.referring_domain,
        forceSsl: location.trackingProperties.google_analytics.force_ssl ?? false,
        isGA4: false,
      } as TrackingPropertiesUA;
    }
    if (!isEmpty(location.trackingProperties.google_analytics4)) {
      ga4Properties = {
        trackerId: location.trackingProperties.google_analytics4.id,
        isGA4: true,
      } as TrackingPropertiesGA4;
    }
  }

  if (!!uaProperties) {
    if (
      isEmptyString(uaProperties.trackerId) ||
      isEmptyString(uaProperties.trackerName) ||
      isEmptyString(uaProperties.referringDomain)
    ) {
      console.error(
        `Could not fire tracking! 
         "trackingId" (${uaProperties.trackerId}), "trackerName" (${uaProperties.trackerName}), "referringDomain" (${uaProperties.referringDomain})
         must be defined correctly for location ${location.id}`
      );
      uaProperties = null;
      if (!ga4Properties) {
        return;
      }
    }
  }

  if (!!ga4Properties) {
    if (isEmptyString(ga4Properties.trackerId)) {
      console.error(
        `Could not fire tracking! 
         "trackingId" (${ga4Properties.trackerId})
         must be defined correctly for location ${location.id}`
      );
      ga4Properties = null;
      if (!uaProperties) {
        return;
      }
    }
  }

  if (!!ga4Properties) {
    fireGaTracking({ trackingProperties: ga4Properties, trackingEvent });
  }
  if (!!uaProperties) {
    fireGaTracking({ trackingProperties: uaProperties, trackingEvent });
  }
};

export { fireGoogleAnalytics };
