import { put, takeEvery, call } from 'redux-saga/effects';
import { LocationConsentFormsSagas } from './core/locationConsentForms';
import { buildAwsPath, getS3ConsentFormSigningUrl } from '../core/util/locationConsentForms';
import { isProd } from '../config';
import { log } from '../core/logger/log';

export class ConsentFormUploadSagas {
  static UPLOAD_CONSENT_FORM = 'sagas/UPLOAD_CONSENT_FORM';
}

const s3SignedUrl = ({ name, type }) =>
  fetch(getS3ConsentFormSigningUrl(name, type), {
    method: 'GET',
    headers: {
      'Content-Type': type,
    },
  }).then((res) => res.json());

const putToS3 = (url, { image, type, contentDisposition }) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': type,
      'x-amz-server-side-encryption': 'AES256',
      'Content-Disposition': contentDisposition,
    },
    body: image,
  });

function* uploadConsentForm({ file, displayName, locationId, groupId, onSuccess, onFailure }) {
  try {
    const bucket = isProd() ? 'solv-consent-forms' : 'bin-test-upload';

    const awsPath = buildAwsPath(file.name, groupId);

    const image = { awsPath, image: file, type: file.type, name: awsPath };
    const { signedRequest } = yield call(s3SignedUrl, image);

    const s3UploadResponse = yield call(putToS3, signedRequest, image);

    if (s3UploadResponse && s3UploadResponse.ok) {
      log.debug('S3 upload success');

      // creating location consent form record in db
      yield put({
        type: LocationConsentFormsSagas.CREATE_FORM,
        displayName,
        locationId,
        groupId,
        awsBucketName: bucket,
        awsPath,
        onSuccess,
        onFailure,
      });
    } else {
      if (onFailure) onFailure();
    }
  } catch (e) {
    log.error(e);
    if (onFailure) onFailure();
  }
}

export default function* rootSaga() {
  yield takeEvery(ConsentFormUploadSagas.UPLOAD_CONSENT_FORM, uploadConsentForm);
}
