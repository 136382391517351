import { isEmptyArray } from './array';
import { ServiceIdentifiers } from '../../constants';
import { Location } from '../../types/RootState';

const isIntegrationEnabled = (location: Pick<Location, 'serviceLocations'>, serviceId: number) => {
  if (isEmptyArray(location?.serviceLocations)) return false;

  const activeIntegrations = location.serviceLocations.filter(
    (serviceLocation) => serviceLocation.service_identifier === serviceId
  );

  return activeIntegrations.length !== 0;
};

const isIntegrationStatusEnabled = (location: Pick<Location, 'serviceLocations'>) => {
  return (
    isIntegrationEnabled(location, ServiceIdentifiers.MEDSTAR.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.DOCUTAP.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.ATHENA.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.ECW.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.PRACTICE_VELOCITY.id)
  );
};

export { isIntegrationStatusEnabled, isIntegrationEnabled };
