import { DAPI_HOST } from '../../config';

const getEligibilityByUserProfileIdAndBookingId = (userProfileId, bookingId) =>
  `${DAPI_HOST}/v1/eligibility?user_profile_id=${userProfileId}&booking_id=${bookingId}`;

const getEligibilityByInsuranceProfileIdAndBookingId = (insuranceProfileId, bookingId) =>
  `${DAPI_HOST}/v1/eligibility?insurance_profile_id=${insuranceProfileId}&booking_id=${bookingId}`;

const getEligibilityCheckUrl = () => `${DAPI_HOST}/v1/eligibility`;

const getEligibilityIdByBookingId = bookingId =>
  `${DAPI_HOST}/v1/eligibility?booking_id=${bookingId}`;

const getFaceSheetUrl = eligibilityCheckId =>
  `${DAPI_HOST}/v1/eligibility/${eligibilityCheckId}/details`;

const getInsuranceDetailsUrl = (eligibilityCheckId, insuranceProfileId, serviceCode) =>
  `${DAPI_HOST}/v2/eligibility/${eligibilityCheckId}/details` +
  `?insurance_profile_id=${insuranceProfileId}&service_code=${serviceCode}`;

const getEligibilityFeedbackUrl = eligibilityId =>
  `${DAPI_HOST}/v1/eligibility/${eligibilityId}/feedback`;

export {
  getEligibilityByUserProfileIdAndBookingId,
  getEligibilityByInsuranceProfileIdAndBookingId,
  getEligibilityCheckUrl,
  getEligibilityFeedbackUrl,
  getEligibilityIdByBookingId,
  getInsuranceDetailsUrl,
  getFaceSheetUrl,
};
