import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetJson } from '../core/dapi';
import moment from 'moment';
import { isProd } from '../config';
import { analyticsTrackEvent } from '../core/analytics';
import * as Sentry from '@sentry/nextjs';
import { getRandomInt } from '../core/util/math';
import { MANAGE_UPDATE } from '../core/analytics/events';

type Mode = 'normal' | 'kiosk' | 'telemed' | 'facesheet';

export interface NewVersionState {
  updateAvailableShowAlertTime: number | undefined;
  checkingForUpdate: boolean;
  lastHeartBeat: string | undefined;
  currentManageVersion: string | undefined;
  mode: Mode;
}

const initialState: NewVersionState = {
  updateAvailableShowAlertTime: undefined,
  checkingForUpdate: false,
  lastHeartBeat: undefined,
  currentManageVersion: undefined,
  mode: 'normal',
};

export const checkForNewVersion = createAsyncThunk('newVersion/check', async () => {
  try {
    const json = await apiGetJson(`/heartbeat.json?_=${Math.random()}`);
    if (json.data?.value) {
      return json.data.value;
    }
    return undefined;
  } catch (ex) {
    Sentry.captureException(ex);
    return undefined;
  }
});

export const newVersionSlice = createSlice({
  initialState,
  name: 'newVersion',
  reducers: {
    setManageVersion: (state, action: PayloadAction<string | undefined>) => {
      state.currentManageVersion = action.payload;
    },
    setMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkForNewVersion.pending, (state) => {
      state.checkingForUpdate = true;
    });
    builder.addCase(checkForNewVersion.fulfilled, (state, action) => {
      state.checkingForUpdate = false;

      const heartbeat = action.payload;

      if (!heartbeat) {
        return;
      }

      if (!state.lastHeartBeat) {
        state.lastHeartBeat = heartbeat;
        return;
      }

      // NEXT_PUBLIC_MANAGE_VERSION is injected at build time (and doesn't change unless a new version of the app is loaded)
      // So this can be used to tell if we are on the latest version or not.
      const currentVersion = state.currentManageVersion;
      let isUpdateAvailable;

      if (currentVersion && currentVersion !== heartbeat) {
        isUpdateAvailable = true;
      } else {
        isUpdateAvailable = state.lastHeartBeat !== heartbeat;
      }

      console.debug(
        'currentVersion',
        currentVersion,
        'lastHeartBeat',
        state.lastHeartBeat,
        'newHeartBeat',
        heartbeat,
        'isUpdateAvailable',
        isUpdateAvailable
      );

      if (isUpdateAvailable) {
        // If we already have set a time to show the update alert, we don't want to keep re-checking and re-updating the time.
        if (!state.updateAvailableShowAlertTime) {
          // May take some minutes for the update to propagate to all the nodes, so don't show an update alert until 10-15 minutes later.
          const showAt = moment()
            .add(isProd() ? getRandomInt(10, 15) : 1, !isProd() ? 'second' : 'minute')
            .toDate();
          state.updateAvailableShowAlertTime = showAt.getTime();
          console.debug('checking manage update at', showAt.toLocaleString());
          analyticsTrackEvent(MANAGE_UPDATE, {
            action: 'available',
            currentVersion,
            newVersion: heartbeat,
          });
        }
      } else {
        state.updateAvailableShowAlertTime = undefined;
      }
    });
  },
});

export const { setManageVersion, setMode } = newVersionSlice.actions;
