import { PaperworkField } from '../../../queries/useLocationPaperworkFieldsQuery';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingV2 } from '../../../types/dapi/Booking';

export interface KioskState {
  paperwork: Record<string, any>;
  booking: Partial<BookingV2>;
}

const initialState: KioskState = {
  paperwork: {},
  booking: {
    phone: '',
  },
};

export const kioskSlice = createSlice({
  initialState,
  name: 'kiosk',
  reducers: {
    resetState: (_) => {
      return initialState;
    },
    setPaperwork: (state, action: PayloadAction<PaperworkField[]>) => {
      state.paperwork = action.payload;
    },
    setBookingData: (state, action: PayloadAction<Partial<BookingV2>>) => {
      state.booking = {
        ...state.booking,
        ...action.payload,
      };
    },
  },
});

export const { setPaperwork, setBookingData, resetState } = kioskSlice.actions;
