import intersection from 'lodash/intersection';
import moment from 'moment-timezone';
import has from 'lodash/has';
import {
  REVIEW_DEFAULT_MINIMUM_RATING,
  clinicHasCustomConfiguration,
  getClinicConfigAttribute,
  E2E_TESTING_LOCATION_ID,
  COVID_19_VAX_FORM_LOCATIONS,
} from '../../../config/clinic';
import {
  KAUFMAN_COUNTY_CITY_OF_TERRELL_GROUP_ID,
  PIERCE_COUNTY_WA_GROUP_ID,
  SPECIALYSTS_MINNESOTA_GROUP_ID,
  URGENT_CARE_FOR_KIDS_GROUP_ID,
} from '../../../config/groups';
import { MINIMUM_HIGHLY_RATED_FOR } from '../../../config';
import {
  LOCATION_TYPE_PARTNER,
  LOCATION_TYPE_BOOKABLE,
  LOCATION_TYPE_OTHER,
  EMPTY_STRING,
  SELF_PAY_ONLY,
  TYPE_SELF,
  TYPE_PPO,
  SpecialtyTypes,
  GROUPS_CONTACTLESS_TIME_CONSTRAINTS,
  PLATFORM_TYPE_PROVIDER_GROUP,
} from '../../../constants';
import { isNextCareUser, isSolvExpressLocation } from '../../session';
import { EMPTY_ARRAY, isEmptyArray, safeMap, sortAlphabetical } from '../array';
import { isEmpty } from '../empty';
import { formatInsurerType } from '../insurance';
import { isEmptyObject, safeGet } from '../object';
import { titleCase } from '../string';
import { isTimeLastMinuteOrSecondOfDay, TIME_MIDNIGHT } from '../time';
import {
  AccountLocation,
  AccountLogin,
  Booking,
  Location,
  LocationHour,
  LocationHours,
  LocationUnformatted,
  Rating,
} from '../../../types/RootState';
import { Hours, LocationV2 } from '../../../types/dapi/Location';
import { hasBeenDischarged, isTokboxTelemedBooking } from '../booking';

/**
 * Filter ratings down to those we want to display in the
 * "Highly Rated For" section of the CDP.
 * @param {Array} ratings
 * @return {Array}
 */
const validHighlyRatedFor = (ratings: Rating[]) => {
  if (!ratings || ratings.constructor !== Array) {
    return [];
  }

  const filtered = ratings.filter(
    (rating) =>
      rating.score >= MINIMUM_HIGHLY_RATED_FOR &&
      rating.topic !== 'other' &&
      rating.topic !== 'overall'
  );
  for (const rating of filtered) {
    rating.label = rating.topic.replace('_', ' ');
    rating.label = rating.label.charAt(0).toUpperCase() + rating.label.slice(1);
  }

  return filtered;
};
import { isAccountLocation } from '../../../types/guards';
import { queryGeneralConfigs, useQueryGeneralConfigs } from '../../../hooks/useGeneralConfigs';

const getTodaysHours = (location: Location) =>
  location.hours[moment().format('dddd') as keyof LocationHours];

const isClosedToday = (hours: LocationHours | Hours | undefined) =>
  hours && isEmptyArray(hours[moment().format('dddd') as keyof LocationHours]);

const getSecondaryNameFromLocation = (
  location?:
    | Pick<Location, 'displayNameSecondary'>
    | Pick<LocationUnformatted, 'display_name_secondary'>
) =>
  location && 'displayNameSecondary' in location
    ? location.displayNameSecondary
    : location?.display_name_secondary || '';

const getPrimaryNameFromLocation = (
  location?:
    | Pick<Location, 'displayNamePrimary'>
    | Pick<LocationUnformatted, 'display_name_primary'>
) =>
  location && 'displayNamePrimary' in location
    ? location.displayNamePrimary
    : location?.display_name_primary || '';

const formatLocationNameByPrimaryAndSecondaryNames = (
  primaryName: string,
  secondaryName: string
) => {
  if (secondaryName) {
    return `${primaryName} - ${secondaryName}`;
  }

  return primaryName;
};

const formatLocationName = (
  location:
    | Pick<Location, 'displayNamePrimary' | 'displayNameSecondary'>
    | Pick<LocationUnformatted, 'display_name_primary' | 'display_name_secondary'>
) => {
  const primaryName = getPrimaryNameFromLocation(location);
  const secondaryName = getSecondaryNameFromLocation(location);

  return formatLocationNameByPrimaryAndSecondaryNames(primaryName, secondaryName);
};

const formatLocationSelectName = (
  location: Pick<Partial<Location>, 'id'> &
    (
      | Pick<Location, 'displayNamePrimary' | 'displayNameSecondary'>
      | Pick<LocationUnformatted, 'display_name_primary' | 'display_name_secondary'>
    ),
  options: { includeId?: boolean; isSolvEmployee?: boolean } = {}
) => {
  const primaryName = getPrimaryNameFromLocation(location);
  const secondaryName = getSecondaryNameFromLocation(location);

  if (!options.isSolvEmployee) {
    return secondaryName || primaryName;
  }

  let result = `${secondaryName} (${primaryName})`;
  if (options.includeId && location.id) {
    result += ` (${location.id})`;
  }

  return result;
};

const getOptionDisplayName = (location: Location & LocationUnformatted) =>
  location.display_name_secondary ? location.display_name_secondary : location.display_name_primary;

const formatLocationNameFromBooking = (booking: Booking) => {
  if (booking.location_display_name_primary) {
    return booking.location_display_name_secondary
      ? `${booking.location_display_name_primary} - ${booking.location_display_name_secondary}`
      : booking.location_display_name_primary;
  }

  return booking.location_name;
};

const formatLocationDisplayAddress = (location: Location & LocationUnformatted) => {
  let address = location.display_address || location.displayAddress;

  if (location.subpremise) {
    address = `${address}, ${location.subpremise}`;
  }

  address = `${address}, ${location.city}, ${location.state} ${location.zipCode}`;

  return address;
};

const isSelfPayOnly = (location: Location & LocationUnformatted) =>
  safeGet(location, false)(`acceptedInsuranceTypes.${SELF_PAY_ONLY}`);

const isCannedChatTextOnly = (location: Location & LocationUnformatted) =>
  safeGet(location, false)('isCannedTextChatOnlyEnabled') ||
  safeGet(location, false)('is_canned_text_chat_only_enabled');

const getAcceptedInsuranceTypes = (
  location: Location & LocationUnformatted,
  opts: { formatInsurerType?: boolean } = {}
) => {
  const acceptedInsuranceTypes = [TYPE_SELF];

  if (!isSelfPayOnly(location)) {
    Object.entries({
      ...safeGet(location, {})('acceptedInsuranceTypes'),
    }).forEach(([key, value]) => {
      if (value) {
        acceptedInsuranceTypes.push(key);
      }
    });

    if (!acceptedInsuranceTypes.includes(TYPE_PPO)) {
      acceptedInsuranceTypes.push(TYPE_PPO);
    }
  }

  if (isEmptyArray(acceptedInsuranceTypes)) {
    return acceptedInsuranceTypes;
  }

  if (opts.formatInsurerType) {
    return acceptedInsuranceTypes.map(formatInsurerType);
  }

  return acceptedInsuranceTypes;
};

const getInsurerTypesDisplay = (location: Location & LocationUnformatted) =>
  getAcceptedInsuranceTypes(location, { formatInsurerType: true }).join(', ');

const hasHours = (location: Location & LocationUnformatted) => {
  if (isEmptyObject(location.hours)) {
    return false;
  }

  for (const dayOfWeek in location.hours) {
    if (
      has(location.hours, dayOfWeek) &&
      !isEmptyArray(location.hours[dayOfWeek as keyof LocationHours])
    ) {
      return true;
    }
  }

  return false;
};

const isOpen = (location: Location & LocationUnformatted) => {
  if (!hasHours(location)) {
    return false;
  }

  const openClosePairs = getTodaysHours(location);
  const now = moment();
  const todayDate = now.format('YYYY-MM-DD');

  return openClosePairs.some((pair) => {
    const open = moment.tz(`${todayDate}T${pair.fromTime}`, location.timeZone);
    const close = moment.tz(`${todayDate}T${pair.toTime}`, location.timeZone);
    return now.isAfter(open) && now.isBefore(close);
  });
};

const isCheckinOpen = async (location: Location & LocationUnformatted) => {
  const data = await queryGeneralConfigs({
    fields: ['isCheckinAllowedOutsideBusinessHours', 'checkinOutsideBusinessHoursInterval'],
    inheritFromGroup: true,
    locationId: location.id,
  });

  if (!data?.values.isCheckinAllowedOutsideBusinessHours) {
    return isOpen(location);
  }

  if (!hasHours(location)) {
    return false;
  }

  const openClosePairs = getTodaysHours(location);
  const now = moment();
  const todayDate = now.format('YYYY-MM-DD');

  return openClosePairs.some((pair) => {
    const open = moment.tz(`${todayDate}T${pair.fromTime}`, location.timeZone);
    const close = moment.tz(`${todayDate}T${pair.toTime}`, location.timeZone);
    open.subtract(data.values.checkinOutsideBusinessHoursInterval, 'm');
    close.add(data.values.checkinOutsideBusinessHoursInterval, 'm');
    return now.isAfter(open) && now.isBefore(close);
  });
};

const isClosingSoon = (location: Location & LocationUnformatted, thresholdMinutes = 60) => {
  if (!hasHours(location) || !isOpen(location)) {
    return null;
  }

  const openClosePairs = getTodaysHours(location);
  const now = moment();
  const todayDate = now.format('YYYY-MM-DD');

  return openClosePairs.some((pair) => {
    const close = moment.tz(`${todayDate}T${pair.toTime}`, location.timeZone);
    const threshold = moment(close).subtract(thresholdMinutes, 'minutes');
    return now.isAfter(threshold) && now.isBefore(close);
  });
};

const getLocationType = (location: Location & LocationUnformatted) => {
  if (location.isSolvPartner) {
    return LOCATION_TYPE_PARTNER;
  } else if (location.isBookable) {
    return LOCATION_TYPE_BOOKABLE;
  }

  return LOCATION_TYPE_OTHER;
};

const getLocalizedHours = (hours: Array<LocationHour>, locationTimeZone: string) => {
  const todayDate = moment().format('YYYY-MM-DD');

  return hours.map((hour) => ({
    fromTime: moment.tz(`${todayDate}T${hour.fromTime}`, locationTimeZone),
    toTime: moment.tz(`${todayDate}T${hour.toTime}`, locationTimeZone),
  }));
};

const getMinimumReviewRating = (locationId: string) =>
  clinicHasCustomConfiguration(locationId, 'review_minimum_rating')
    ? getClinicConfigAttribute(locationId, 'review_minimum_rating')
    : REVIEW_DEFAULT_MINIMUM_RATING;

const determineIfSolvExpressLocation = (locationId: string = '', locations: AccountLocation[]) => {
  if (locations?.length > 0) {
    const location = locations.find((location) => location.location_id === locationId);
    return Boolean(location && isSolvExpressLocation(location));
  }
  return false;
};

const isTelemedLocation = (
  location: Partial<Location> | Partial<AccountLocation> | Partial<LocationV2>
) => {
  if (!location) {
    return false;
  }

  if ('isTelemed' in location) {
    return location.isTelemed;
  }

  if ('is_telemed' in location) {
    return location.is_telemed;
  }

  return false;
};

const includesTelemedLocation = (locations: Array<Location> | Array<AccountLocation>) =>
  locations.some((loc) => isTelemedLocation(loc));

const isAsapTelemedEnabled = (
  location: (Partial<Location> & Partial<LocationUnformatted>) | Partial<LocationV2> | undefined
) => location && location.isAsapTelemedEnabled;

const isStandaloneTelemedLocation = (location: Location & LocationUnformatted) =>
  location && location.isStandaloneTelemed;

const isPaperworkEnabled = (location: (Location & LocationUnformatted) | LocationV2) =>
  location && location.isPaperworkEnabled;

const isConsentEnabled = (location: Location & LocationUnformatted) =>
  location && location.isConsentEnabled;

const isBookingCodesEnabled = (location: Location & LocationUnformatted) =>
  location?.isBookingCodesEnabled;

const isKioskCustomQuestionsEnabled = (location: Location & LocationUnformatted) =>
  location.isKioskCustomQuestionsEnabled;

const getLocationHours = (location: Location & LocationUnformatted) => {
  const hourData = getTodaysHours(location);
  const hours = getLocalizedHours(hourData, location.timeZone);
  return hours.map((hour, i) => (
    <span key={i}>
      {`${hour.fromTime.format('h:mm a')} - ${hour.toTime.format('h:mm a')} `}(
      {hour.fromTime.zoneAbbr()})
    </span>
  ));
};

const isValidLocation = (location: Location & LocationUnformatted) => hasHours(location);

const getAlphabetizedLocationsGroupedByState = (
  locations: Array<Location & LocationUnformatted>
) => {
  const sortedLocations = sortAlphabetical(locations, formatLocationName);
  const groupedLocations: Record<string, Array<Location & LocationUnformatted>> = {};
  sortedLocations.forEach((location) => {
    if (!(location.state in groupedLocations)) {
      groupedLocations[location.state] = [];
    }

    groupedLocations[location.state].push(location);
  });

  return groupedLocations;
};

const getAlphabetizedListOfStates = (locations: Array<Location & LocationUnformatted>) =>
  Array.from(new Set(locations.map((location) => location.state))).sort();

const getEncryptedLocationIdNumeric = (locationIdNumeric: number, size = 2, minLength = 6) => {
  // location id: 21 -> 00-00-21
  // location id: 12902 -> 01-29-02
  if (isEmpty(locationIdNumeric)) {
    return null;
  }
  let id = locationIdNumeric.toString();
  if (id.length < minLength) {
    const zeros = '0'.repeat(minLength - id.length);
    id = zeros + id;
  }
  let i = 0;
  const res = [];
  while (i < id.length) {
    res.push(id.slice(i, i + size));
    i += size;
  }
  return res.join('-');
};

const getSpecialOperatingHoursReasonDisplayValue = (reason: string) => {
  switch (reason) {
    case 'update!':
      return 'Unit Test';
    case 'holiday':
    case 'other':
      return titleCase(reason);
    case 'noProvider':
      return 'No Provider';
    case 'powerOutage':
      return 'Power Outage';
    default:
      return reason;
  }
};

const removeSpecialOperatingHoursBeforeToday = (hours: Array<LocationHour>, timeZone: string) =>
  isEmptyArray(hours)
    ? hours
    : hours.filter((hour) =>
        moment(hour.effective_date, 'Y-MM-DD').isSameOrAfter(moment.tz(timeZone), 'day')
      );

const isDisableReservationsOn = (location: Location & LocationUnformatted) => {
  if (location.isFormatted) {
    return moment
      .tz(location.disableReservationsUntil, location.timeZone)
      .isSameOrAfter(moment.tz(location.timeZone));
  }

  return moment
    .tz(location.disable_reservations_until, location.time_zone)
    .isSameOrAfter(moment.tz(location.time_zone));
};

const isTestLocation = (
  location:
    | null
    | undefined
    | Pick<Location, 'isTestLocation'>
    | Pick<LocationUnformatted, 'is_test_location'>
) => {
  if (location == null) {
    return false;
  }

  if ('is_test_location' in location) {
    return !!location.is_test_location;
  }

  return !!location.isTestLocation;
};

const isPaymentsEnabled = (location: Location & LocationUnformatted) =>
  !isEmptyObject(location) && location.isPaymentsEnabled;

const getOpeningTimeForWeekday = (location: Location & LocationUnformatted, weekDay: string) => {
  const hours = safeGet(location, [{ fromTime: EMPTY_STRING }])(`hoursDefault.${weekDay}`);

  return isEmptyArray(hours) ? EMPTY_STRING : hours[0].fromTime;
};

const getClosingTimeForWeekday = (
  location: Location & LocationUnformatted,
  weekDay: string
): string => {
  const hours = safeGet(location, [{ toTime: '' }])(`hoursDefault.${weekDay}`);

  return isEmptyArray(hours) ? EMPTY_STRING : hours[0].toTime;
};

/**
 * Returns a boolean indicating whether a location is closed on a given day.
 *
 * Because we support special operating hours, which can override the operating hours on a specified
 * date, this function takes an option `considerSpecialHours`.  If true, we look at location.hours,
 * which incorporates special operating hours.  If false, we look at location.hoursDefault, which
 * does not incorporate special operating hours
 * @param location {Object} location object
 * @param day {String} day of week name
 * @param opts {Object}
 */
const isClosedOnDay = (
  location: Location & LocationUnformatted,
  day: string,
  opts: { considerSpecialHours?: boolean } = {}
) => {
  if (!('considerSpecialHours' in opts)) {
    throw new Error(
      'You must indicate whether you want to consider special hours (location.hours) or not (location.hoursDefault)'
    );
  }

  if (opts.considerSpecialHours) {
    return location.hours && isEmpty(location.hours[day as keyof LocationHours]);
  }

  return location.hoursDefault && isEmpty(location.hoursDefault[day as keyof LocationHours]);
};

const isOpen24HoursOnDay = (
  location: Location & LocationUnformatted,
  day: string,
  opts: { considerSpecialHours?: boolean } = {}
) => {
  if (isClosedOnDay(location, day, opts)) {
    return false;
  }

  if (!('considerSpecialHours' in opts)) {
    throw new Error(
      'You must indicate whether you want to consider special hours (location.hours) or not (location.hoursDefault)'
    );
  }

  const key = opts.considerSpecialHours ? 'hours' : 'hoursDefault';

  return Boolean(
    location[key] &&
      location[key][day as keyof LocationHours][0].fromTime === TIME_MIDNIGHT &&
      isTimeLastMinuteOrSecondOfDay(location[key][day as keyof LocationHours][0].toTime)
  );
};

const isE2ETestingLocation = (location: Location & LocationUnformatted) =>
  location.id === E2E_TESTING_LOCATION_ID;

const includesE2ETestingLocation = (locations: Array<Location & LocationUnformatted>) =>
  locations.some((location) => isE2ETestingLocation(location));

const isInGroups = (
  location: (Location & LocationUnformatted) | Partial<LocationV2>,
  groupIds: string[]
) => {
  // @ts-ignore
  return intersection(location.groups, groupIds).length > 0;
};

const isInGroup = (location: Location & LocationUnformatted, groupId: string) =>
  (location.groups || EMPTY_ARRAY).find((group) => group?.group_id === groupId);

const isPhysicalLocation = (location: Location | AccountLocation) => !isTelemedLocation(location);

const isDentalSpecialty = (location: Location & LocationUnformatted) =>
  location.specialties && location.specialties.some((s) => s.name === SpecialtyTypes.DENTIST);

const isUrgentCareSpecialty = (location: Location & LocationUnformatted) =>
  isEmpty(location.specialties) ||
  location.specialties.some((s) => s.name === SpecialtyTypes.URGENT_CARE);

const getAcceptedInsurersIds = (location: Location & LocationUnformatted) =>
  safeMap(location.acceptedInsurers, (insurer) => insurer.marketplace_insurer_id);

const getSpecialtiesIds = (location: Location & LocationUnformatted) =>
  safeMap(location.specialties, (specialty) => specialty.specialty_id);

const getBrandIds = (location: (Location & LocationUnformatted) | LocationV2) =>
  safeMap(location.brands, (brand) => brand.id);

const getSpecialtiesFields = (location: Location & LocationUnformatted) =>
  location.specialties.map((specialty) => specialty.specialty_field);

const getLocationGroupId = (
  location?:
    | Pick<Location, 'groups'>
    | Pick<LocationV2, 'groups'>
    | Pick<LocationUnformatted, 'groups'>
) => {
  const firstGroup = location?.groups?.[0];

  return !!firstGroup && 'groupId' in firstGroup ? firstGroup.groupId : firstGroup?.group_id || '';
};

const getLocationGroupName = (
  location?: Pick<Location, 'groups'> | Pick<LocationUnformatted, 'groups'>
) => location?.groups?.[0]?.name ?? '';

const getLocationStateCode = (location?: Pick<Location, 'state'>) => location?.state;

const getAssociatedTelemedLocation = (location: Location & LocationUnformatted) =>
  safeGet(location)('associatedTelemedLocationId');

const isBookAheadOnWalkInSlots = (location: Pick<LocationV2, 'isBookAheadOnWalkInSlots'>) => {
  return !!location.isBookAheadOnWalkInSlots;
};

const isPaperworkRequired = (location: Location & LocationUnformatted) =>
  location && location.isPaperworkRequired;

const isPaymentsRequired = (location: Location & LocationUnformatted) =>
  location && location.isPaymentsRequired;

const shouldShowPaperworkBypass = (location: Location & LocationUnformatted) =>
  location && isPaperworkEnabled(location) && isPaperworkRequired(location);

const shouldShowPaymentBypass = (location: Location & LocationUnformatted) =>
  location && isPaymentsEnabled(location) && isPaymentsRequired(location);

const isPhotoIdUploadEnabled = (location: Location & LocationUnformatted) =>
  location && location.isPhotoIdUploadEnabled;

const isPhotoIdBackRequired = (location: Location & LocationUnformatted) =>
  location && location.isPhotoIdBackRequired;

const shouldShowCovid19VaccinationTab = (location: Location & LocationUnformatted) =>
  isInGroup(location, SPECIALYSTS_MINNESOTA_GROUP_ID) ||
  isInGroup(location, PIERCE_COUNTY_WA_GROUP_ID) ||
  isInGroup(location, KAUFMAN_COUNTY_CITY_OF_TERRELL_GROUP_ID) ||
  COVID_19_VAX_FORM_LOCATIONS.includes(location?.id);

const shouldShowVisitSummaryTab = (booking: Booking) =>
  hasBeenDischarged(booking) && booking?.is_premium_visit && isTokboxTelemedBooking(booking);

// We also include `location.mobleCheckin` because this field is implemented in SQL as mobile_check_in. That way we can
//  tolerate if dapi does not transform the data it gets from SQL.
const isMobileCheckinEnabled = (location: Location | AccountLocation) => {
  if (isAccountLocation(location)) {
    return location.mobile_check_in;
  }
  return (
    !!location &&
    (location.isMobileCheckInEnabled ||
      location.is_mobile_check_in_enabled ||
      location.mobileCheckIn)
  );
};

const getLocationsFromIds = (
  locations: Array<Location & LocationUnformatted> | Record<string, Location & LocationUnformatted>,
  locationIds: string[]
): Location[] => {
  let map: Record<string, Location> = {};

  if (Array.isArray(locations)) {
    locations.forEach((l) => (map[l.id || l.location_id] = l));
  } else {
    map = locations;
  }

  const results: Location[] = [];

  for (let locationId of locationIds) {
    const entry = map[locationId];
    if (entry) {
      results.push(entry);
    }
  }

  return results;
};

const isContactlessClosedWhileLocationOpen = (location: Location & LocationUnformatted) => {
  const groupId = Object.keys(GROUPS_CONTACTLESS_TIME_CONSTRAINTS).find((gId) =>
    isInGroup(location, gId)
  );

  if (!groupId) {
    return false;
  }

  const [openTime, closeTime] = GROUPS_CONTACTLESS_TIME_CONSTRAINTS[groupId];

  const todayString = moment.tz(location.timeZone).format('YYYY-MM-DD');
  const openMoment = moment.tz(
    `${todayString} ${openTime}`,
    'YYYY-MM-DD HH:mm:ss',
    location.timeZone
  );
  const closeMoment = moment.tz(
    `${todayString} ${closeTime}`,
    'YYYY-MM-DD HH:mm:ss',
    location.timeZone
  );
  return !moment.tz().isBetween(openMoment, closeMoment);
};

const isAllTelemedLocations = (locations: Array<Location & LocationUnformatted>) =>
  locations.every((location) => isTelemedLocation(location));

const isAllInPersonLocations = (locations: Array<Location & LocationUnformatted>) =>
  locations.every((location) => !isTelemedLocation(location));

const isProviderGroupLocation = (location: Location | AccountLocation) => {
  if (isAccountLocation(location)) {
    return location.platform_type === PLATFORM_TYPE_PROVIDER_GROUP;
  }
  return (location?.platformType ?? location?.platform_type) === PLATFORM_TYPE_PROVIDER_GROUP;
};

const isLegalNameRequired = (
  location: (Location & LocationUnformatted) | Pick<LocationV2, 'isLegalNameRequired'>
) => location?.isLegalNameRequired;

const isUrgentCareForKidsLocation = (location: Location & LocationUnformatted) =>
  !!location?.groups?.some?.((group) => group.group_id === URGENT_CARE_FOR_KIDS_GROUP_ID);

const isCustomQuickRepliesEnabled = (location: Location & LocationUnformatted) =>
  location.is_custom_quick_reply_enabled || location.isCustomQuickReplyEnabled;

const isExternalTelemedLocation = (location: Location | LocationUnformatted) => {
  if ('isExternalTelemed' in location) {
    return location.is_external_telemed;
  }
  return location.is_external_telemed;
};

export {
  determineIfSolvExpressLocation,
  formatLocationDisplayAddress,
  formatLocationName,
  formatLocationNameFromBooking,
  formatLocationNameByPrimaryAndSecondaryNames,
  getAlphabetizedListOfStates,
  getAlphabetizedLocationsGroupedByState,
  getClosingTimeForWeekday,
  getInsurerTypesDisplay,
  getLocationsFromIds,
  getLocalizedHours,
  getLocationType,
  getMinimumReviewRating,
  getOpeningTimeForWeekday,
  getOptionDisplayName,
  getSpecialOperatingHoursReasonDisplayValue,
  getTodaysHours,
  hasHours,
  isCannedChatTextOnly,
  isClosingSoon,
  isClosedToday,
  isDisableReservationsOn,
  isInGroup,
  isLegalNameRequired,
  isOpen,
  isCheckinOpen,
  isOpen24HoursOnDay,
  isPhysicalLocation,
  isTelemedLocation,
  isStandaloneTelemedLocation,
  isDentalSpecialty,
  isUrgentCareSpecialty,
  isPaperworkEnabled,
  isConsentEnabled,
  isBookingCodesEnabled,
  isKioskCustomQuestionsEnabled,
  getLocationHours,
  isValidLocation,
  removeSpecialOperatingHoursBeforeToday,
  isTestLocation,
  isPaymentsEnabled,
  isE2ETestingLocation,
  isClosedOnDay,
  getAcceptedInsurersIds,
  getSpecialtiesIds,
  getBrandIds,
  getSpecialtiesFields,
  getLocationGroupId,
  isBookAheadOnWalkInSlots,
  isPaperworkRequired,
  isPaymentsRequired,
  shouldShowPaperworkBypass,
  shouldShowPaymentBypass,
  isPhotoIdUploadEnabled,
  isAsapTelemedEnabled,
  getLocationStateCode,
  getLocationGroupName,
  getAssociatedTelemedLocation,
  isMobileCheckinEnabled,
  getEncryptedLocationIdNumeric,
  isPhotoIdBackRequired,
  isContactlessClosedWhileLocationOpen,
  isInGroups,
  includesTelemedLocation,
  includesE2ETestingLocation,
  isAllTelemedLocations,
  isAllInPersonLocations,
  shouldShowCovid19VaccinationTab,
  shouldShowVisitSummaryTab,
  isProviderGroupLocation,
  getSecondaryNameFromLocation,
  getPrimaryNameFromLocation,
  isUrgentCareForKidsLocation,
  isCustomQuickRepliesEnabled,
  formatLocationSelectName,
  isExternalTelemedLocation,
};
