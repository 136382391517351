import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Button, Popover, Select } from 'antd';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { EMPTY_ARRAY } from '../../core/util/array';
import { isMobile } from '../../core/util/device';
import { getOptionDisplayName } from '../../core/util/location';
import { pluralize } from '../../core/util/string';
import { filterSearchOption } from '../utils';
import FilterableCheckboxGroup from './FilterableCheckboxGroup';
import { selectorContainerStyles, selectorPopoverStyles, selectorTitleStyles } from './styles';
import { fetchLocations } from '../../ducks/locations';
import { useDispatch } from '../../types/Redux';

const StyledPlaceholder = styled.span`
  color: ${colors.black};
`;

const StyledLocationsSelectorContainer = styled.div`
  ${selectorContainerStyles};
  button {
    width: 225px;
    span {
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const StyledTitle = styled.h3`
  ${selectorTitleStyles};
`;

const StyledSingleLocationPopover = styled.div`
  .ant-select-dropdown {
    li {
      height: 40px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.14px;
      padding-left: 20px !important;
      border-bottom: 1px solid ${colors.shadowGrey} !important;
      display: flex;
      align-items: center;
      &:hover {
        background-color: ${colors.lightPink};
      }
      &:last-child {
        border-bottom: none !important;
      }
    }
    .ant-select-dropdown-menu-item-selected {
      background-color: ${colors.white};
      color: ${colors.solvPink};
    }
  }
`;

// https://github.com/yesmeck/styled-antd/issues/1#issuecomment-406271810
// eslint-disable-next-line react/prop-types
const MyPopover = ({ className, ...props }) => <Popover {...props} overlayClassName={className} />;

const StyledPopover = styled(MyPopover)`
  ${selectorPopoverStyles};
`;

const LocationsSelector = ({
  appliedOptions,
  className,
  defaultSelectedLocation,
  disabled,
  disabledLocations,
  emptyStateLabel,
  isSolvEmployee,
  limit,
  locationOptions,
  multipleSelection,
  onButtonSubmit,
  onLocationSelectValueChange,
  onVisibleChange,
  selectedOptions,
  setSelectedOptions,
  shouldDisableButton,
  shouldShowLimitText,
  shouldShowTitle,
  visible,
}) => {
  const dispatch = useDispatch();

  const fetchLocationIfNotExist = (id) => {
    dispatch(
      fetchLocations({
        locationIds: [].concat(id),
      })
    );
  };

  const getLocationName = (location) => {
    if (!location) {
      return '';
    }
    if (isSolvEmployee) {
      return `${location.display_name_secondary} (${location.display_name_primary})`;
    }
    return location.display_name_secondary || location.display_name_primary;
  };

  const getLocationValue = (location) => location.location_id;

  const getDropdownLabel = () => {
    const filteredCheckedLocationIds = selectedOptions.filter(
      (id) => !disabledLocations.includes(id)
    );
    const checkedLocationsCount = filteredCheckedLocationIds.length;

    if (checkedLocationsCount === 0) return emptyStateLabel;

    if (checkedLocationsCount === 1) {
      const checkedLocation = locationOptions.find((l) => l.location_id === selectedOptions[0]);
      return checkedLocation ? getLocationName(checkedLocation) : emptyStateLabel;
    }

    return `${checkedLocationsCount} ${pluralize(checkedLocationsCount, 'location')} selected`;
  };

  const overlayStyle = {};
  if (isMobile()) {
    overlayStyle.width = '100%';
  }

  const popoverProps = {};
  if (visible !== undefined) popoverProps.visible = visible;

  return (
    <StyledLocationsSelectorContainer
      className={`${className} locations-selector`}
      data-testid="locationsSelector"
    >
      {shouldShowTitle && <StyledTitle>Location</StyledTitle>}
      <StyledSingleLocationPopover id="single-location-popover" />
      {multipleSelection ? (
        <StyledPopover
          {...popoverProps}
          onVisibleChange={onVisibleChange}
          trigger="click"
          placement="bottomLeft"
          overlayStyle={overlayStyle}
          content={
            <FilterableCheckboxGroup
              getLabel={getLocationName}
              getValue={getLocationValue}
              options={locationOptions}
              appliedOptions={appliedOptions}
              onChange={(value) => {
                fetchLocationIfNotExist(value);
                onLocationSelectValueChange(value);
              }}
              sortBy={getOptionDisplayName}
              value={selectedOptions}
              limit={limit}
              disabledValues={disabledLocations}
              onButtonSubmit={onButtonSubmit}
              shouldDisableButton={shouldDisableButton}
              shouldShowLimitText={shouldShowLimitText}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          }
        >
          <Button disabled={disabled} className="location-selector-select-button">
            {getDropdownLabel()}
            <Icon type="down" />
          </Button>
        </StyledPopover>
      ) : (
        <Select
          showSearch
          defaultValue={defaultSelectedLocation}
          placeholder={<StyledPlaceholder>Select a location</StyledPlaceholder>}
          optionFilterProp="children"
          onChange={(value) => {
            fetchLocationIfNotExist(value);
            onLocationSelectValueChange(value);
          }}
          filterOption={filterSearchOption}
          getPopupContainer={() => document.getElementById('single-location-popover')}
          data-testid="singleLocationsSelector"
        >
          {locationOptions.map((location, id) => (
            <Select.Option key={id} value={location.location_id}>
              {getLocationName(location)}
            </Select.Option>
          ))}
        </Select>
      )}
    </StyledLocationsSelectorContainer>
  );
};

LocationsSelector.defaultProps = {
  disabledLocations: EMPTY_ARRAY,
  shouldShowTitle: true,
  emptyStateLabel: 'Select locations',
};

LocationsSelector.propTypes = {
  appliedOptions: PropTypes.array,
  className: PropTypes.string,
  defaultSelectedLocation: PropTypes.string,
  disabled: PropTypes.bool,
  disabledLocations: PropTypes.array,
  emptyStateLabel: PropTypes.string,
  isSolvEmployee: PropTypes.bool,
  limit: PropTypes.number,
  locationOptions: PropTypes.array,
  multipleSelection: PropTypes.bool,
  onButtonSubmit: PropTypes.func,
  onLocationSelectValueChange: PropTypes.func,
  onVisibleChange: PropTypes.func,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  shouldDisableButton: PropTypes.bool,
  shouldShowLimitText: PropTypes.bool,
  shouldShowTitle: PropTypes.bool,
  visible: PropTypes.bool,
};

export default LocationsSelector;
