export const colors = {
  greyest: '#212121',
  notQuiteBlack: '#243242',
  fadeToBlack: '#313131',
  lightBlack: '#333333',
  tar: '#404040',
  squid: '#414f60',
  greyWay: '#5b6b7d',
  mrGray: '#666',
  greyOcean: '#709EBF',
  wholeGrayn: '#84909E',
  greyBae: '#8c98a4',
  lanaDelGrey: '#979797',
  greyLock: '#9da6b1',
  graydient: '#a5aeb8',
  theGreyAlbum: '#b3b3b3',
  greyWorm: '#c4c4c4',
  greyish: '#d9d9d9',
  dorianGrey: '#dcdcdc',
  shadowGrey: '#e9e6e6',
  amazingGrayce: '#ececec',
  whiteGrey: '#ededed',
  fatherJohnMisty: '#f3f3f3',
  foggyGrey: '#f8f9fb',
  grayDim: '#f9f9f9',
  theWhiteAlbum: '#fff',
  greytWhiteShark: '#e3e9ee',
  greyHound: '#b4c2cc',
  greyMatter: '#f8f8f8',
  grayvy: '#b4c2cd',
  grayMatter: '#6a7685',
  grey200: '#E3E5E6',
  grey300: '#C6CACF',
  grey600: '#222A33',
  antGrey: '#e9e9e9',
  darkGray: '#4D4D4D',
  graysonChance: '#DEE4E7',
  miGrayne: '#909090',
  lightBlueGray: '#567B8F',
  limeade: '#50AF07',
  invalidRed: '#F5222D',
  warningYellow: '#FFA400',

  lightPink: '#ffe7f3',
  crunchberry: '#ff0e88', // NAMING: crunchBerry
  solvPink: '#ff62b2',
  cherryPie: '#f0006a',
  ladyInRed: '#ff234e',
  pinkish: '#ed6daf',
  redAlert: '#ce2424',
  antDanger: '#ff4d4f', // the 'danger' color used in the antd lib

  peaches: '#f5a623', // and cream
  lemonade: '#f5ff93',
  /* Closest yellow to antd's "warning" color that's still accessible against a white background. */
  a11yYellowAgainstWhite: '#946300',

  greenPine: '#3ad5ab',
  seaFoam: '#3ecbbb',
  grannySmith: '#45c071',
  coralGreen: '#17c2b9',
  greenMachine: '#19d1c7',

  blueLoadingScreen: '#516072',
  blueMonday: '#5b6b7d',
  blueish: '#809db2',
  karl: '#edf4f9',
  primaryBlue: '#108ee9',
  bahamasBlue: '#00669d',
  tangledUpInBlue: '#0084cc',
  whiteBlue: '#B5DFF6',
  blueJayWay: '#169ae1',
  blueMQV: '#179be1',
  mrBlueSky: '#40b6f5',
  gradientBlue: '#f1fbff',
  backgroundBlue: '#f5f9fb',
  greyBlue: '#A6C7DF',
  theHolyGrayl: '#709ebf',
  gotTheBlues: '#e5eaee',
  comeSailAway: '#10d1b2',
  blueDefaultCTAHover: '#2caff5',
  blueDefaultCTAActive: '#0487cc',
  reportsCTA: '#0071DB',

  bloop: '#f0a6ff',
  bruisePurple: '#693673',
  purpleRain: '#682e9a',
  purpleHaze: '#af5ebf',
  pomegranate: '#d76eac',
  purpleGradLight: '#bf5eaf',
  purpleGradDark: '#9f5ebf',
  purpleSky: '#9036d5',
  lightGreyBg: '#f3f4f5',
  lightGetbg2: '#fafafa',
  lightGrey3: '#f2f2f2',
  lightGreyBg2: '#f5f5f5',
  softBlue: '#5fb4ef',

  white: '#fff', // theWhiteAlbum
  black: '#313131', // theBlackAlbum
  blackestBlack: '#000',
  link: '#0084cc', // tangledUpInBlue

  border: {
    blue: '#40a9ff',
  },
};

// { textColor: backgroundColor }
// textColor is the value stored in the database
export const QUEUE_LOCATION_LABEL_COLORS = {
  '#C5261B': '#FFDDDB',
  '#B83E18': '#FFE4DB',
  '#C1411A': '#FFF0E5',
  '#B83E19': '#FFE3C2',
  '#A26016': '#FFF3DB',
  '#995B15': '#FFE8BB',
  '#905614': '#FFDE9F',
  '#9D5E15': '#FFF09F',
  '#9D5E16': '#FFF4BC',
  '#787511': '#F6FFBC',
  '#787412': '#F0FF95',
  '#667311': '#E3FF95',
  '#667312': '#D0FF95',
  '#427311': '#B7FF95',
  '#457811': '#D7FFC4',
  '#117E15': '#C4FFC9',
  '#117E50': '#C4FFEA',
  '#188155': '#DCFFF2',
  '#177D7D': '#DCFFFB',
  '#157A7A': '#BFFFF7',
  '#177387': '#BFF7FF',
  '#1A798E': '#DCF7FF',
  '#19708A': '#CCF0FF',
  '#166EA2': '#D6F0FF',
  '#1866AF': '#C4E6FF',
  '#1761A6': '#C4DBFF',
  '#2254D3': '#CAD9FF',
  '#2C5EDD': '#DEE7FF',
  '#4252E0': '#DFDEFF',
  '#2637C5': '#B6B5FF',
  '#4A28D2': '#C8B5FF',
  '#5F4BE2': '#E7DEFF',
  '#791BC5': '#E2BCFF',
  '#8E28E2': '#EFDAFF',
  '#8D18B4': '#F1BBFF',
  '#AF1ABC': '#FADBFF',
  '#A9196F': '#FFC2EE',
  '#B8199E': '#FFDBF7',
  '#596488': '#E1E4ED',
  '#5C5C5C': '#D5D5D5',
};
