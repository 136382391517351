import {
  BOOKING_RESPONSE_RECEIVED,
  BOOKING_REQUEST_FAILED,
  BOOKING_REQUEST_SUBMITTED,
  BOOKING_REQUEST_NOT_SUBMITTED,
  BOOKING_SIGN_IN_FORM_SUBMITTED,
  CLEAR_BOOKING,
  LANG_CHANGE,
  BOOKING_ADD_FIELDS,
  BOOKING_ADD_PENDING_FIELDS,
  BOOKING_CLEAR_PENDING_FIELDS,
  BOOKING_CLEAR_TIME_AND_PLACE,
} from '../constants';

export default function bookingReducer(state = {}, action) {
  switch (action.type) {
    case LANG_CHANGE:
      return {
        ...state,
        language: action.payload.value,
      };
    case BOOKING_RESPONSE_RECEIVED:
      return {
        ...state,
        lastCreatedBookingId: action.payload.value.booking_id,
        error: null,
        errorCount: 0,
        submitting: false,
        insurance: null,
        profile: null,
        booking: null,
      };
    case BOOKING_SIGN_IN_FORM_SUBMITTED:
      return {
        ...state,
        submitting: true,
        booking: {
          ...state.booking,
          ...action.payload.value.booking,
        },
        profile: {
          ...state.profile,
          ...action.payload.value.profile,
        },
      };
    case BOOKING_REQUEST_FAILED:
      return {
        ...state,
        error: action.payload.value,
        errorCount: state.errorCount + 1,
        submitting: false,
      };
    case BOOKING_REQUEST_SUBMITTED:
      return {
        ...state,
        submitting: true,
      };
    case BOOKING_REQUEST_NOT_SUBMITTED:
      return {
        ...state,
        submitting: false,
      };
    case BOOKING_ADD_FIELDS:
      return {
        ...state,
        booking: {
          ...state.booking,
          ...action.payload.value,
        },
      };
    case BOOKING_ADD_PENDING_FIELDS:
      return {
        ...state,
        pendingBooking: {
          ...action.payload.value,
        },
      };

    case BOOKING_CLEAR_PENDING_FIELDS:
      return {
        ...state,
        pendingBooking: {},
      };

    case CLEAR_BOOKING:
      return {
        ...state,
        booking: {},
        pendingBooking: {},
      };

    case BOOKING_CLEAR_TIME_AND_PLACE: {
      const newState = { ...state };
      const booking = { ...state.booking };
      delete booking.locationId;
      delete booking.appointmentDate;
      newState.booking = booking;
      return newState;
    }

    default:
      return state;
  }
}
