import { useEffect } from 'react';
import { GenericObject } from '../../core/util/generics';
import { v4 as uuidV4 } from 'uuid';

type PubNubMessageRaw = {
  message: {
    payload: {
      update_type: PubNubEvent[];
      attributes: string | GenericObject;
    };
  };
};

export enum PubNubSource {
  Local,
  Remote,
}

type PubNubMessage = {
  type: PubNubEvent;
  payload: any;
  source: PubNubSource;
};

export enum PubNubEvent {
  RefreshActiveBookings = 'refresh_active_bookings',
  BookingTransferred = 'booking_transferred',
  BookingUpdated = 'booking_updated',
  PartialBookingUpdate = 'partial_booking_update',
  RatingNew = 'rating_new',
  BookingNew = 'booking_new',
  RefreshLocation = 'refresh_location',
  RefreshQueue = 'refresh_queue',
  MessageNew = 'message_new',
  ConversationNew = 'conversation_new',
  WaitListCountUpdate = 'wait_list_count_update',
}

type Callback = (message: PubNubMessage) => void;

export const PubNubSubscribers: Record<string, Callback> = {};

export const PubNubListeners = {
  dispatchRaw(raw: PubNubMessageRaw, source: PubNubSource) {
    const message: PubNubMessage = {
      source,
      type: raw.message.payload.update_type[0],
      payload:
        typeof raw.message.payload.attributes === 'string'
          ? JSON.parse(raw.message.payload.attributes)
          : raw.message.payload.attributes,
    };
    PubNubListeners.dispatch(message);
  },
  dispatch(message: PubNubMessage) {
    for (let callback of Object.values(PubNubSubscribers)) {
      callback(message);
    }
  },
};

export function usePubNubSubscribe(callback: Callback, id?: string) {
  useEffect(() => {
    const subscriberId = id || uuidV4();
    PubNubSubscribers[subscriberId] = callback;
    return () => {
      delete PubNubSubscribers[subscriberId];
    };
  }, [callback, id]);
}
